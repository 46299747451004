DocumentsDownloadController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'DocumentsService',
	'DocumentsTypesService',
	'notification',
	'_',
	'$uibModal'
];

export default function DocumentsDownloadController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	DocumentsService,
	DocumentsTypesService,
	notification,
	_,
	$uibModal
) {
	const vm = this;

	vm.loading = false;
	vm.isOpen = vm.isOpen || false;
	vm.params = $stateParams;
	vm.state = $state;
	vm._ = _;
	vm.$uibModal = $uibModal;

	vm.getDocuments = getDocuments;
	vm.displayDocuments = displayDocuments;
	vm.print = print;
	vm.sauvegarder = sauvegarder;
	vm.savePartagePortail = savePartagePortail;
	vm.getDomainPortails = getDomainPortails;
	vm.createDocument = createDocument;
	vm.editerDocument = editerDocument;
	vm.updateDoc = updateDoc;
	vm.enableEditMode = enableEditMode;
	vm.disableEditMode = disableEditMode;

	vm.setSelectedFiltre = setSelectedFiltre;
	vm.preselectFiltre = preselectFiltre;
	vm.documentLie = { hasDocs: false };

	vm.removeDoc = removeDoc;
	vm.currentEditedDocumentList = [];

	vm.$onInit = () => {
		console.log(vm.domaine);

		vm.unregister = $scope.$watch(
			() => vm.isOpen,
			async function () {
				if (vm.isOpen) {
					await displayDocuments();
					await getDomainPortails();
				}
			},
			true
		);
	};

	vm.$onDestroy = () => {
		vm.unregister();
	};

	async function getDocuments() {
		startLoading();
		vm.allDocs = await DocumentsService.getFiltres();
		await displayDocuments();
		stopLoading();
	}

	async function displayDocuments() {
		try {
			if (vm.id) {
				vm.documentLie = await DocumentsService.getDocumentsDownload(vm.id, vm.domaine);
				if (vm.documentLie.isVisible && vm.isOpen) {
					vm.preselectFiltre();
				}
			} else {
				vm.documentLie.isVisible = false;
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	async function print(fileName, extension, code) {
		startLoading();
		const resultat = await DocumentsService.print(fileName, extension, code);

		const data = resultat.data;
		let headers = resultat.headers;

		headers = headers();

		const contentType = headers['content-type'];

		const linkElement = document.createElement('a');
		try {
			const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
			const url = window.URL.createObjectURL(blob);
			linkElement.setAttribute('href', url);
			linkElement.setAttribute('download', fileName + '.' + extension);

			const clickEvent = new MouseEvent('click', {
				view: window,
				bubbles: true,
				cancelable: false
			});
			linkElement.dispatchEvent(clickEvent);
		} finally {
			stopLoading();
		}
	}

	async function createDocument() {
		const modalInstance = vm.$uibModal.open({
			animation: true,
			backdrop: 'static',
			size: 'md',
			component: 'documentModalForm',
			resolve: {
				data: () => {
					return { idOrigine: vm.id, typeOrigine: vm.domaine };
				}
			}
		});
		vm.loading = true;
		modalInstance.result.then(
			async (document) => {
				document.selected = true;
				vm.allDocs.push(document);
				await vm.sauvegarder();

				vm.loading = false;
			},
			() => {
				vm.loading = false;
			}
		);
	}

	async function editerDocument(index) {
		vm.state.go('documents.edit', { id: vm.documentLie.documents[index].idDocument });
	}

	async function removeDoc(index) {
		delete vm.documentLie.documents[index].selected;
		const filtre = vm.allDocs.find((e) => {
			return e.id === vm.documentLie.documents[index].idDocument;
		});
		if (filtre) {
			delete filtre.selected;
		}
		await vm.sauvegarder();
	}

	async function setSelectedFiltre() {
		await vm.sauvegarder();
	}

	function preselectFiltre() {
		vm.documentLie.hasDocs = false;
		vm._.forEach(vm.allDocs, (e) => {
			delete e.selected;

			const filtre = vm._.find(vm.documentLie.documents, { idDocument: e.id });
			if (filtre) {
				filtre.selected = true;
				e.selected = true;
				vm.documentLie.hasDocs = true;
			}
		});
	}

	async function updateDoc(document) {
		if (vm.currentEditedDocumentList.length > 0) {
			const documentCopyIndex = vm.currentEditedDocumentList.findIndex((e) => e.id === document.id);
			vm.currentEditedDocumentList.splice(documentCopyIndex, 1)[0];
		}

		if (vm.domaine === 4) {
			document.idDomainPortail = document.idDomainPortail || null;
			await vm.savePartagePortail(document);
		}

		const filtre = vm.allDocs.find((e) => {
			return e.id === document.idDocument;
		});
		if (filtre) {
			filtre.libelle = document.libelle;
		}

		document.isEdit = false;

		await vm.sauvegarder();
	}

	async function sauvegarder() {
		try {
			startLoading();

			const lstIdNamePair = {};
			const selectedDocs = vm.allDocs
				.filter((e) => {
					return e.selected;
				})
				.map((e) => {
					return { id: e.id, libelle: e.libelle };
				});
			for (let i = 0; i < selectedDocs.length; i++) {
				lstIdNamePair[selectedDocs[i].id] = selectedDocs[i].libelle;
			}

			await DocumentsService.updateFiltre(vm.id, vm.domaine, lstIdNamePair);
			await vm.displayDocuments();
			stopLoading();
		} catch (ex) {
			notification.error(ex.data);
			return false;
		}
	}

	async function savePartagePortail(document) {
		let idSite = +vm.id;
		await DocumentsService.updatePartagePortail(document.idDocument, document.isPartagePortail, document.idDomainPortail, idSite);
	}

	async function getDomainPortails() {
		startLoading();
		try {
			vm.domainPortails = await DocumentsService.getDomainPortails();
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	function enableEditMode(document) {
		vm.currentEditedDocumentList.push({ ...document }); // pour garder une copie le document en cas d'annulation de l'edition;
		document.isEdit = true;
	}

	function disableEditMode(document) {
		document.isEdit = false;
		const documentCopyIndex = vm.currentEditedDocumentList.findIndex((e) => e.id === document.id);
		const documentCopy = vm.currentEditedDocumentList.splice(documentCopyIndex, 1)[0];
		angular.copy(documentCopy, document);
	}
}

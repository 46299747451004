export default class MassiaApplicationController {
	static $inject = ['$scope', 'MassiaApplicationService', 'MassiaClientService', 'MassiaAuthService', 'VersionService', '$uibModal'];

	constructor($scope, MassiaApplicationService, MassiaClientService, MassiaAuthService, VersionService, $uibModal) {
		this.$scope = $scope;
		this.MassiaApplicationService = MassiaApplicationService;
		this.MassiaClientService = MassiaClientService;
		this.MassiaAuthService = MassiaAuthService;
		this.versionSvc = VersionService;
		this.$uibModal = $uibModal;
	}

	$onInit() {
		this.application = this.MassiaApplicationService.application;
		if (this.application === 'massia') {
			this.application = __configuration.defaultApp;
		}
		this.watcher = this.$scope.$watch(
			() => this.MassiaApplicationService.application,
			() => {
				this.application = this.MassiaApplicationService.application;
			}
		);
		this.MassiaApplicationService.setApplication(this.application);
		this.MassiaClientService.setClient(this.client);
		this.MassiaAuthService.authenticateUser(this.MassiaApplicationService.getApplication());

		this.preventMouseWheelOnInputNumber();

		this.versionSvc.getVersion().then((version) => {
			this.version = version;
			const current = localStorage.getItem('version');
			console.log(current, version);
			if (current !== version) {
				this.$uibModal.open({
					animation: true,
					backdrop: 'static',
					ariaLabelledBy: 'modal-title',
					ariaDescribedBy: 'modal-body',
					templateUrl: 'version-reload.html',
					controller: [
						'$scope',
						function ($scope) {
							$scope.version = version;
							$scope.reload = function () {
								localStorage.setItem('version', version);
								window.onbeforeunload = null;
								window.location.reload(true);
							};
						}
					]
				});
			}
		});
	}

	$onDestroy() {
		if (this.watcher) {
			this.watcher();
		}
		this.MassiaApplicationService.resetApplication();
	}

	preventMouseWheelOnInputNumber() {
		document.addEventListener('onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll', function (event) {
			if (
				event &&
				event.currentTarget &&
				event.currentTarget.activeElement &&
				event.currentTarget.activeElement.type &&
				event.currentTarget.activeElement.type === 'number'
			) {
				event.currentTarget.activeElement.blur();
				//event.preventDefault();
			}
		});
	}
}

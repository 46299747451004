import { Component } from '../../../core/decorators';
import { StateParams, StateService } from '@uirouter/angularjs';
import { TypeAideService } from '../massia-service/type.aide.service';
import DocumentsService from '../../../app/common/documents/services/documents.service';
import Document from '../../../app/common/documents/components/document-form/document.model';

@Component({
	selector: 'massia-type-aide',
	bindings: {
		entity: '=',
		entityDomaine: '='
	},
	template: require('./massia.type.aide.tpl.html')
})
export default class MassiaTypeAideController {
	$state: StateService;
	$stateParams: StateParams;
	entity: any;
	entityDomaine: number;
	TypeAideService: TypeAideService;
	DocumentsService: DocumentsService;
	typesAide: any;
	documents: Document[];
	$uibModal: ng.ui.bootstrap.IModalService;
	/* @ngInject */
	constructor(
		$state: StateService,
		$stateParams: StateParams,
		TypeAideService: TypeAideService,
		DocumentsService: DocumentsService,
		$uibModal: ng.ui.bootstrap.IModalService
	) {
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.TypeAideService = TypeAideService;
		this.DocumentsService = DocumentsService;
		this.$uibModal = $uibModal;
	}

	async $onInit() {
		await this.getTypeAide();
		//On récupère automatiquement les docs, l'idTypeAide se met parfois à jour plus tard
		await this.getDocumentListe();
	}

	$onDestroy() {}

	async getTypeAide() {
		const typeAide = await this.TypeAideService.getTypeAide();
		if (typeAide) {
			return (this.typesAide = typeAide);
		}
	}

	async selectItem(list: any, data: any) {
		if (data) {
			this.entity.aideUrl = null;
			this.entity.document = null;
			this.entity.aideToolType = null;

			if (data.id !== this.entity.idTypeAide) {
				this.entity.idTypeAide = data.id;
			} else {
				list = list.map((e: { selected: boolean }) => (e.selected = !e.selected));

				this.entity.idTypeAide = null;
				if (this.entity.aideUrl) this.entity.aideUrl = null;
				if (this.entity.document) this.entity.document = null;
				if (this.entity.aideToolType) this.entity.aideToolType = null;
			}

			if (this.entity.idTypeAide === 3) {
				await this.getDocumentListe();
			} else if (this.entity.idTypeAide === 2) {
				this.entity.aideUrl = 'http://';
			}
		}
	}

	selectDocument(list: any, newId: number) {
		if (newId) {
			if (!this.entity.document || (this.entity.document && newId !== this.entity.document.id)) {
				this.entity.document = this.documents.find((doc) => doc.id == newId);
			} else {
				list = list.map((e: { selected: boolean }) => (e.selected = !e.selected));

				if (this.entity.document) this.entity.document = null;
			}
		}
	}

	async getDocumentListe() {
		try {
			const doc = await this.DocumentsService.getDocuments([], [], { skip: 0, take: 0 });
			if (doc) {
				this.documents = doc.items;
			}
		} catch (ex) {
			//console.log(ex);
		}
	}

	async AddDocument() {
		let result: Document = null;
		try {
			const modalInstance = this.$uibModal.open({
				component: 'documentModalForm',
				resolve: {
					data: () => {
						return { idOrigine: this.entity.id, typeOrigine: this.entityDomaine };
					}
				},
				backdrop: false
			});

			result = await modalInstance.result;

			if (result) {
				const newId = result.id;
				await this.getDocumentListe(); //On recharge la liste pour avoir un formatage correct des données
				this.selectDocument(this.documents, newId);
			}
		} catch (ex) {
			console.error(ex);
		}
	}
}

export default class RequestNavController {
	constructor($scope, $state, UserRequestService) {
		this.$scope = $scope;
		this.$state = $state;
		this._navigationList = [];
		this.UserRequestService = UserRequestService;
	}

	$onInit() {
		this.tmp = this.UserRequestService.request;
		this.countAddReq = this.UserRequestService.userAddRequest;
		this.createNavList();
		this.$scope.$watch(
			() => this.UserRequestService.request,
			() => (this._navigationList[0].badge = this.UserRequestService.request.length),
			true
		);
		this.$scope.$watch(
			() => this.UserRequestService.userAddRequest,
			() => (this._navigationList[1].badge = this.UserRequestService.userAddRequest.length),
			true
		);
	}

	createNavList() {
		this.tmp = this.UserRequestService.request;
		this.countAddReq = this.UserRequestService.userAddRequest;
		this._navigationList = [
			{
				libelle: `{{'PORTAIL.GROUP.REQUEST_UPDATED' | translate}}`,
				href: 'portail.request.list',
				active: false,
				badge: this.tmp.length
			},
			{
				libelle: `{{'PORTAIL.GROUP.REQUEST_ADD' | translate}}`,
				href: 'add.user.request.list',
				active: false,
				badge: this.countAddReq.length
			}
		];
	}
}

RequestNavController.$inject = ['$scope', '$state', 'UserRequestService'];

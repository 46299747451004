const serviceUrl = `${__configuration.apiUrl}/massia/releve-bons`;

export default class ReleveBonsService {
	static $inject = ['$http'];

	constructor($http) {
		this.$http = $http;
	}

	async getReleveBons(filters, sorts, pagination, idSocieteCom, idSiteCom, dateDebut, dateFin, entreeSortie, achatVente) {
		const res = await this.$http.get(serviceUrl, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {}),
				idSocieteCom: JSON.stringify(idSocieteCom || 0),
				idSiteCom: JSON.stringify(idSiteCom || 0),
				dateDebut: JSON.stringify(dateDebut || null),
				dateFin: JSON.stringify(dateFin || null),
				entreeSortie: JSON.stringify(entreeSortie || null),
				achatVente: JSON.stringify(achatVente || null)
			}
		});
		return res.data;
	}

	async getSocietesCom() {
		const url = `${__configuration.apiUrl}/massia/societes-commerciales`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getSitesCom(pIdSociete) {
		const url = `${__configuration.apiUrl}/massia/sites-commerciaux/header/${pIdSociete}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async print(tickets, fileName, idSocieteCom, idSiteCom, dateDebut, dateFin, sendBlToPortail) {
		console.log(sendBlToPortail);
		const url = `${serviceUrl}/print`;
		const res = await this.$http.post(
			url,
			{
				releves: tickets,
				fileName: fileName,
				idSocieteCommerciale: idSocieteCom,
				idSiteCommercial: idSiteCom,
				dateDebut: dateDebut,
				dateFin: dateFin,
				sendBlToPortail: sendBlToPortail
			},
			{
				responseType: 'arraybuffer'
			}
		);

		return res;
	}

	async getPrintConfiguration() {
		const res = await this.$http.get(`${serviceUrl}/configuration-impression`);
		console.log('🚀 ~ file: releve.bons.service.js:60 ~ ReleveBonsService ~ getPrintConfiguration ~ res:', res);
		return res.data;
	}

	async saveOrUpdatePrintConfiguration(print) {
		console.log('🚀 ~ file: releve.bons.service.js:64 ~ ReleveBonsService ~ saveOrUpdatePrintConfiguration ~ print:', print);
		try {
			const res = await this.$http.post(`${serviceUrl}/configuration-impression/${print.id}`, print);
			console.log('🚀 ~ file: releve.bons.service.js:68 ~ ReleveBonsService ~ saveOrUpdatePrintConfiguration ~ res:', res);
			return 'ok';
		} catch (error) {
			console.log('🚀 ~ file: releve.bons.service.js:68 ~ ReleveBonsService ~ saveOrUpdatePrintConfiguration ~ error:', error);
		}
	}
}

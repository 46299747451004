import angular from 'angular';

export default class StocksConfigurationProduitsController {
	static $inject = [
		'$scope',
		'$state',
		'$uibModal',
		'notification',
		'$translate',
		'$filter',
		'moment',
		'StocksConfigurationProduitsService',
		'MassiaRightsService',
		'localStorageService',
		'_'
	];

	constructor(
		$scope,
		$state,
		$uibModal,
		notification,
		$translate,
		$filter,
		moment,
		StocksConfigurationProduitsService,
		MassiaRightsService,
		localStorageService,
		_
	) {
		this.$scope = $scope;
		this.$state = $state;
		this.$uibModal = $uibModal;
		this.notification = notification;
		this.$translate = $translate;
		this.$filter = $filter;
		this.moment = moment;
		this.StocksConfigurationProduitsService = StocksConfigurationProduitsService;
		this.MassiaRightsService = MassiaRightsService;
		this.localStorageService = localStorageService;
		this._ = _;
		this.loading = false;
		this.idSite = null;
		this.idNiveau = 0;
		this.sites = [];
		this.niveaux = [];
		this.produits = [];
		this.produitsCibles = [];
		this.isConfigGenerale = true;
		this.idConfig = 0;
		this.produitCiblesOrdered = undefined;
	}

	async $onInit() {
		await this.loadNiveauxHierarchique();

		if (this.niveauxHierarchique.length > 0) {
			this.niveaux = [{ id: 0, label: '---' }, ...this.niveauxHierarchique];
		}
		await this.loadSites();
		const stockSiteId = this.localStorageService.get('STOCK_CONFIG_PROD_STOCK_SITE_ID');

		if (this.sites.length > 0) {
			this.idSite = stockSiteId || this.sites[0].id;
			if (this.idSite) {
				await this.loadProduits();
				// DECOMMENTER POUR ACTIVER LA FONCTIONNALITE UNE FOIS LE REPORT FAIT
				// await this.loadProduitsCiblesGroupeSite();
			}
		}

		this.$scope.$watch('$ctrl.idSite', async (newValue, oldValue) => {
			if (newValue !== oldValue) {
				this.localStorageService.set('STOCK_CONFIG_PROD_STOCK_SITE_ID', newValue);
				this.init();
				// DECOMMENTER POUR ACTIVER LA FONCTIONNALITE  UNE FOIS LE REPORT FAIT
				// await this.loadProduitsCiblesGroupeSite();
			}
		});
		this.config = [
			{ id: 0, label: this.$translate.instant('STOCKS_CONFIGURATION_PRODUITS.CONFIGURATION_GENERALE') },
			{ id: 1, label: this.$translate.instant('STOCKS_CONFIGURATION_PRODUITS.CONFIGURATION_TRANSFERT_PRODUIT_CIBLE') }
		];
	}

	handleChangeConfig() {
		this.isConfigGenerale = this.idConfig === 0;
	}

	init() {
		this.idNiveau = 0;
		this.idConfig = 0;
		this.produits = [];
		this.produitsCibles = [];
		this.produitCiblesOrdered = undefined;
		this.isConfigGenerale = true;
	}

	async loadSites() {
		this.startLoading();
		try {
			this.sites = await this.StocksConfigurationProduitsService.getSitesCommerciauxSansSociete();
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async loadNiveauxHierarchique() {
		try {
			this.niveauxHierarchique = await this.StocksConfigurationProduitsService.getProduitsStocksElements();
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async loadProduits() {
		this.startLoading();
		try {
			const niveau = this.idNiveau !== 0 ? this.idNiveau.niveauHierarchiqueId + ';' + this.idNiveau.elementId : 'ALL';
			this.produits = await this.StocksConfigurationProduitsService.getProduitsBySiteAndNiveau(this.idSite, niveau);
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async loadProduitsCiblesGroupeSite() {
		this.startLoading();
		try {
			this.produitsCibles = await this.StocksConfigurationProduitsService.getProduitsCiblesGroupeSiteBySite(this.idSite);
			await this.getElementsSelectionnables();
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	copyList(produit) {
		return this.setSelected(angular.copy(this.produitCiblesOrdered), produit);
	}

	setSelected(liste, produit) {
		const idsProduitCible = produit.produitCibleIds;
		//on supprime de la liste le produit
		const index = liste.findIndex((item) => item.idProduit === produit.id && item.idProducteur === produit.producteurId);
		if (index > -1) {
			liste.splice(index, 1);
		}

		if (idsProduitCible.length > 0) {
			return liste.map((item) => {
				const truc = idsProduitCible.includes(item.idProduit);
				return { ...item, selected: truc };
			});
		}

		return liste;
	}

	debug() {
		console.log('🔥: StocksConfigurationProduitsController -> this.produits', this.produits);
	}

	getNiveaux(produit, niveau) {
		if (niveau === 0) return this.niveauxHierarchique;

		if (this.niveauxHierarchique && produit.niveau0ToString && niveau === 1) {
			const niveau0 = this.niveauxHierarchique.find((niveau0) => niveau0.id.elementId === produit.niveau0ToString.split(';')[1]);
			if (niveau0) return niveau0.items;
		}
		if (this.niveauxHierarchique && produit.niveau0ToString && produit.niveau1ToString && niveau === 2) {
			const parent = this.niveauxHierarchique.find((niveau0) => niveau0.id.elementId === produit.niveau0ToString.split(';')[1]);
			const niveau1 = parent.items.find((niveau1) => niveau1.id.elementId === produit.niveau1ToString.split(';')[1]);
			return niveau1.items;
		}
	}

	goToProduit(produit) {
		const url = this.$state.href('produits.edit', { id: produit.id });
		window.open('gestion.html' + url);
	}

	goToFormule(produit, domain, action) {
		if (!this.MassiaRightsService.userHasRight(domain, action, 'gestion')) return;
		const url = this.$state.href('formules.produit', { produitId: produit.id, producteurId: produit.producteurId });
		window.open('gestion.html' + url);
	}

	handleChange($event, produit) {
		produit.isChanged = true;
	}

	isValidate(produit) {
		return produit.niveau0ToString === null || produit.niveau1ToString === null || produit.niveau2ToString === null;
	}

	isChangedProductList() {
		return this.produits.some((produit) => produit.isChanged);
	}

	isProducteur(produit) {
		return produit.producteurId === this.idSite;
	}

	isEmptyNiveau2() {
		return this.produits.filter((produit) => produit.niveau2ToString === null).length > 0;
	}

	// boutons de validation
	async sauvegarder() {
		if (!this.isEmptyNiveau2()) {
			this.startLoading();
			try {
				const produitsChangedToUpdate = this.produits.filter((produit) =>
					produit.isChanged
						? produit.niveau0ToString !== null && produit.niveau1ToString !== null && produit.niveau2ToString !== null
						: true
				);
				if (produitsChangedToUpdate.length === 0) {
					this.notification.error('STOCKS_CONFIGURATION_PRODUITS.NO_PRODUCT_CHANGED');
					return false;
				}

				await this.StocksConfigurationProduitsService.updateProduitsStock(this.idSite, produitsChangedToUpdate);
				this.notification.success('STOCKS_CONFIGURATION_PRODUITS.SUCCESS_UPDATE');
			} catch (ex) {
				this.notification.error(ex.data);
				return false;
			} finally {
				this.stopLoading();
			}
			await this.loadProduits();
		} else {
			this.notification.error('STOCKS_CONFIGURATION_PRODUITS.ONE_ROW_NOT_COMPLETE');
			return false;
		}
	}

	onSelectProduitCible(produitSelected, produit) {
		const idsProduitCible = produit.produitCibleIds;
		if (produitSelected) {
			const index = idsProduitCible.indexOf(produitSelected.idProduit);
			if (index > -1) {
				idsProduitCible.splice(index, 1);
			} else {
				idsProduitCible.push(produitSelected.idProduit);
			}
			produit.isChanged = true;
		}
	}

	getLabels(liste) {
		if (liste.length > 0) {
			return (
				liste
					.filter((x) => x.selected)
					.map((item) => item.libelleProduit)
					.join(', ') || 'aucun'
			);
		}
		return 'aucun';
	}

	async getElementsSelectionnables() {
		try {
			if (typeof this.produitCiblesOrdered == 'undefined') {
				let indexToDelete = -1;
				let allProduits = angular.copy(this.produitsCibles);
				allProduits.splice(indexToDelete, 1);

				// Distinct par produit / producteur
				const result = allProduits.reduce((prev, el) => {
					if (prev.some((o) => o.idProducteur == el.idProducteur && o.idProduit == el.idProduit)) return prev;
					return [...prev, el];
				}, []);

				this.produitCiblesOrdered = await this.prepareListeElementsLies(result);
			}
		} catch (err) {
			if (err.data) this.notification.error(err.data);
			else throw err;
		}
	}

	async prepareListeElementsLies(elementsATraiter) {
		var elementsSelectionnablesOrdonnes = this.$filter('orderBy')(elementsATraiter, 'siteProducteur');
		var tmp = null;
		var temp = [];
		for (var index = 0; index < elementsSelectionnablesOrdonnes.length; index++) {
			var currentValue = elementsSelectionnablesOrdonnes[index];
			var toReturn = [];

			//#region Gestion des groupes
			if (tmp == null) {
				//creation du premier groupe
				tmp = currentValue.idProducteur;
				toReturn.push({ libelle: '<span="font-weight:bold">' + currentValue.siteProducteur + '</span>', msGroup: true });
			} else {
				if (tmp != currentValue.idProducteur) {
					//création des autres groupes
					toReturn.push({ msGroup: false });
					tmp = currentValue.idProducteur;
					toReturn.push({ libelle: '<span="font-weight:bold">' + currentValue.siteProducteur + '</span>', msGroup: true });
				}
			}
			//#endregion
			currentValue.libelle = currentValue.codeProduit + ' - ' + currentValue.libelleProduit;
			currentValue.valeur = false;
			currentValue.idComposant = currentValue.idProduit;
			currentValue.code = currentValue.codeProduit;
			// currentValue.idProducteur = currentValue.idProducteur;
			toReturn.push(currentValue);

			if (index == elementsSelectionnablesOrdonnes.length - 1) {
				toReturn.push({ msGroup: false });
			}

			temp.push(toReturn);
		}
		var sorties = this._.flatten(temp);
		return new Promise((resolve) => resolve(sorties));
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}
}

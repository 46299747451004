export default class InerteRegistreAdmissionController {
	static $inject = [
		'$scope',
		'$state',
		'$stateParams',
		'InerteRegistreAdmissionService',
		'notification',
		'$location',
		'$anchorScroll',
		'$translate',
		'MOPService'
	];

	constructor($scope, $state, $stateParams, InerteRegistreAdmissionService, notification, $location, $anchorScroll, $translate, MOPService) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.InerteRegistreAdmissionService = InerteRegistreAdmissionService;
		this.notification = notification;
		this.$location = $location;
		this.$anchorScroll = $anchorScroll;
		this.$translate = $translate;
		this.MOPService = MOPService;

		this.ongletOpen = {
			isEnteteOpen: true
		};
	}

	async $onInit() {
		this.MOPService.setMop([{ title: 'INERTES.BREADCRUMBS.INERTES_LIST', filename: 'GestionDesInertes.pdf', application: 'gestion' }]);
		this.getSites();
	}

	async print() {
		if (this.debut && this.fin && this.idSite) {
			this.startLoading();
			const fileName = Date.now();
			const resultat = await this.InerteRegistreAdmissionService.print(this.idSite, this.debut, this.fin, fileName);

			const data = resultat.data;
			let headers = resultat.headers;

			headers = headers();

			const contentType = headers['content-type'];

			const linkElement = document.createElement('a');
			try {
				const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
				const url = window.URL.createObjectURL(blob);
				linkElement.setAttribute('href', url);
				linkElement.setAttribute('download', fileName + '.xlsx');

				const clickEvent = new MouseEvent('click', {
					view: window,
					bubbles: true,
					cancelable: false
				});
				linkElement.dispatchEvent(clickEvent);
			} catch (ex) {
				//console.log("ex");
			} finally {
				this.stopLoading();
			}
		}
	}

	async getSites() {
		this.startLoading();
		try {
			this.sites = await this.InerteRegistreAdmissionService.getSites();
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async appliquer() {
		await this.print();
	}

	async confirmer() {
		await this.print();
		this.annuler();
	}

	annuler() {
		this.$state.go('gestion.home');
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}
}

export default function RequestRoute($stateProvider) {
	$stateProvider
		.state('portail.request', {
			url: '/request',
			abstract: true,
			template: '<ui-view></ui-view>',
			ncyBreadcrumb: {
				skip: true
			}
		})
		.state('portail.request.nav', {
			url: '',
			template: '<portail-nav-request></portail-nav-request>',
			ncyBreadcrumb: {
				skip: true
			}
		});
	// .state('portail.request.add.user.request.list', {
	//     url: '/ajout',
	//     template: '<add-user-request-list></add-user-request-list>',
	//     ncyBreadcrumb: {
	//         skip: true
	//     }
	// });
}

RequestRoute.$inject = ['$stateProvider'];

import DonneeFiltreeUtilisateursController from './donnee.filtree.utilisateurs.controller';

import DonneeFiltreeUtilisateursTemplate from './donnee.filtree.utilisateurs.html';

export default {
	bindings: {
		personne: '='
	},
	template: DonneeFiltreeUtilisateursTemplate,
	controller: DonneeFiltreeUtilisateursController
};

import angular from 'angular';

//Config
import StocksConfigurationProduitsConfig from './_config/stocks.configuration.produits.config';
import StocksConfigurationProduitsRoutes from './_config/stocks.configuration.produits.routes';

//Components
import StocksConfigurationProduits from './components/stocks-configuration-produits';

//services
import StocksConfigurationProduitsService from './services/stocks.configuration.produits.service';

const moduleName = 'app.massia.gestion.stocks.configuration.produits';

angular
	.module(moduleName, [])
	.config(StocksConfigurationProduitsConfig)
	.config(StocksConfigurationProduitsRoutes)
	.service('StocksConfigurationProduitsService', StocksConfigurationProduitsService)
	.component('stocksConfigurationProduits', StocksConfigurationProduits);

export default moduleName;

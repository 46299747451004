export default class StocksConfigurationProduitsNiveauxService {
	static $inject = ['$http'];

	constructor($http) {
		this.$http = $http;
	}
	async getSitesCommerciauxSansSociete() {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-commerciaux/headers`);
		return res.data;
	}

	async getProduitsStocksElements() {
		let url = `${__configuration.apiUrl}/massia/domaines/produitsstockes/elements-hierarchiques`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getProduitsBySiteAndNiveau2(idSite, idNiveau) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/produits/sitecom/${idSite}/niveau2/${idNiveau}`);
		return res.data;
	}

	async updateProduitsStockNiveau2(idSite, data) {
		const res = await this.$http.put(`${__configuration.apiUrl}/massia/produits/sitecom/${idSite}/niveau2`, data);
		return res.data;
	}
}

ProfilsController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'ProfilsService',
	'MassiaApplicationService',
	'PaginationService',
	'notification',
	'ModalService',
	'$translate',
	'$transitions',
	'MOPService'
];

export default function ProfilsController(
	$scope,
	$state,
	$stateParams,
	ProfilsService,
	MassiaApplicationService,
	PaginationService,
	notification,
	ModalService,
	$translate,
	$transitions,
	MOPService
) {
	/* jshint validthis: true */
	const vm = this;
	const watchers = [];

	let previousTableState;
	vm.selectedProfilId = undefined;
	vm.lastSelected = null;

	// Propriétés
	vm.loading = true;
	vm.params = $stateParams;
	vm.state = $state;
	vm.profils = [];

	vm.slideWidth = '900px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};
	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	// Méthodes
	vm.selectProfil = selectProfil;
	vm.isProfilSelected = isProfilSelected;
	vm.showDetail = showDetail;
	vm.closeDetail = closeDetail;
	vm.loadProfils = loadProfils;
	vm.deleteProfil = deleteProfil;
	vm.setSelected = setSelected;
	vm.eraseAllFilters = eraseAllFilters;

	vm.getTooltipDroits = getTooltipDroits;
	vm.showDocumentation = showDocumentation;
	vm.getSyntheses = getSyntheses;
	vm.MOPService = MOPService;

	// Evènements
	/*vm.$onInit = onInit;
    vm.$onChanges = angular.noop;
    vm.$doCheck = angular.noop;
    vm.$postLink = angular.noop;
    vm.$onDestroy = onDestroy;*/

	// Méthodes publiques
	vm.$onInit = () => {
		vm.MOPService.setMop([{ title: 'Gestion des profils', filename: 'Profils.pdf', application: 'massia' }]);
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));
		if (shouldSetSelectedId()) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	};

	vm.$onDestroy = () => {
		vm.MOPService.resetMop();
	};

	function stateChangeSuccessFunc() {
		if (!$state.params.id) {
			vm.setSelected();
		} else if ($state.params.id) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	function setSelected(profil) {
		if (profil && profil.id) {
			vm.selectedProfilId = $stateParams.id;
			vm.lastSelected = vm.selectedProfilId;
			vm.slideMargin['margin-right'] = vm.slideWidth;
		} else {
			vm.selectedSocieteId = undefined;
			vm.slideMargin['margin-right'] = '0px';
		}
	}

	function shouldSetSelectedId() {
		return $state && $state.current && $state.current.name && $state.current.name === 'profils.list.detail' && $stateParams && $stateParams.id;
	}

	function selectProfil(profil) {
		if (profil && profil.id) {
			if (vm.selectedProfilId !== profil.id) {
				vm.selectedProfilId = profil.id;
				showDetail(profil.id);
			} else {
				vm.selectedProfilId = undefined;
				closeDetail();
			}
		}
	}

	function isProfilSelected(profil) {
		return profil && profil.id && vm.selectedProfilId === profil.id;
	}

	function showDetail(profilId) {
		$state.go('profils.list.detail', { id: profilId });
	}

	function closeDetail() {
		$state.go('profils.list');
	}

	async function loadProfils(tableState) {
		startLoading();

		if ($state && $state.current && $state.current.name !== 'profils.list') {
			$state.go('profils.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.profils = [];

		try {
			const data = await ProfilsService.getProfils(filters, sorts, pagination, MassiaApplicationService.getApplication());

			vm.profils = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deleteProfil(profil) {
		if (profil && profil.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('PROFILS.DELETE.TITLE', { libelle: profil.libelle }),
				modalMsg: $translate.instant('PROFILS.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await ProfilsService.deleteProfilById(profil.id);
					notification.success($translate.instant('SUPPRIMER_SUCCESS', { entity: profil.libelle }));

					vm.selectedProfilId = undefined;

					if (vm.params.id) {
						$state.go('profils.list');
					}
					previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);
					loadProfils();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	function eraseAllFilters() {
		loadProfils();
	}

	function showDocumentation() {}

	function getSyntheses() {}

	function getTooltipDroits(droits) {
		if (droits && droits.length) {
			return '<ul class="text-left"><li>' + droits.join('</li><li>') + '</li></ul>';
		}
		return false;
	}

	// Méthodes privées
	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}

import { isArray } from 'angular';

const serviceUrl = `${__configuration.apiUrl}/massia/gestion/dap`;

export default class DAPsService {
	static $inject = ['$http', 'moment', 'globalizationManagementService'];
	static currentDAPAttente = undefined;
	constructor($http, moment, globalizationManagementService) {
		this.$http = $http;
		this.moment = moment;
		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
	}

	async getDAPs(filters, sorts, pagination) {
		const res = await this.$http.get(serviceUrl, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {})
			}
		});

		return res.data;
	}

	async getFullDapFromAttente(dap) {
		const res = await this.$http.post(`${serviceUrl}/attente/getfull`, {
			params: dap
		});

		return res.data;
	}

	async UpdateChantierOnDapAttente(id, chantier, oldChantier) {
		const data = {
			id: id,
			chantier: {
				id: chantier.id,
				libelle: chantier.libelle,
				ville: chantier.ville,
				codePostal: chantier.codePostal,
				adresse: chantier.adresse,
				latitude: chantier.latitude,
				longitude: chantier.longitude,
				quantite: oldChantier.quantite,
				isChantierDuBatiment: oldChantier.isChantierDuBatiment,
				isChantierReferenceBasol: oldChantier.isChantierReferenceBasol
			}
		};

		const r = await this.UpdateDapAttente(data);

		return r;
	}

	async GetDapAttenteListByDemandeur() {
		const res = await this.$http.get(`${serviceUrl}/attente`, {
			params: {}
		});

		return res.data;
	}

	async RejectDapAttente(id) {
		const etatDap = {
			id: id,
			statut: 4 // Rejetée
		};

		const res = await this.$http.put(`${serviceUrl}/attente`, etatDap);

		console.log('🚀 ~ DAPsService ~ res:', res);
		return res.data;
	}

	async UpdateDapAttenteAsSeen(id) {
		const etatDap = {
			id: id,
			statut: 2 // Attente Lue
		};

		const res = await this.$http.put(`${serviceUrl}/attente`, etatDap);

		console.log('🚀 ~ DAPsService ~ res:', res);
		return res.data;
	}

	async UpdateDapAttente(data) {
		const res = await this.$http.put(`${serviceUrl}/attente`, data);

		console.log('🚀 ~ DAPsService ~ res:', res);
		return res.data;
	}

	async GetDapAttenteById(id) {
		const res = await this.$http.get(`${serviceUrl}/attente/detail/${id}`, {
			params: {}
		});
		console.log(res.data);
		return res.data;
	}

	async deleteDAPById(id) {
		const url = `${serviceUrl}/${id}`;
		return this.$http.delete(url);
	}

	async getDapEnteteById(id) {
		const url = `${serviceUrl}/${id}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async updateEtatDAPById(id, isActive) {
		const url = `${serviceUrl}/etat`;
		const data = { id: id, etat: isActive ? 1 : 0 };
		return await this.$http.put(url, data);
	}

	async createDAPfromAttente(dap, producteur, lieu) {
		const data = {
			numero: 'AUTOMATIQUE',
			dateCreation: this.moment(dap.dateCreationDemande, this.dateFormat).format(this.dateFormat),
			dateDebutValidite: this.moment(dap.dateDebut, this.dateFormat).format(this.dateFormat),
			dateValidite: this.moment(dap.dateFin, this.dateFormat).format(this.dateFormat),
			etat: 0,
			signatureClient: true,
			signatureProducteurDechet: dap.engamementReglementProducteur,
			quantite: dap.chantier.quantiteEstimee,
			siteCommercialId: dap.siteAccueilId,
			producteurId: producteur.id,
			producteurDechetId: !dap.producteur.isDemandeur ? dap.producteur.id : null,
			transporteurIds: dap.transporteur.map((x) => x.id),
			clientDetenteurId: dap.demandeurId,
			chantierOrigineId: dap.chantier.id,
			lieuOrigineId: lieu.id,
			dechets: dap.dechet,
			transporteurSignatures: [],
			longitude: dap.longitude,
			latitude: dap.latitude,
			isChantierDuBatiment: dap.chantier.isChantierDuBatiment,
			isFromPortail: true,
			documents: dap.chantier.documents
		};

		const result = await this.$http.post(serviceUrl, data);

		return result.data;
	}

	async getProducteursBySite(id) {
		const url = `${__configuration.apiUrl}/portail/user/contacts/header/${id}`;
		const res = await this.$http.get(url);

		return res.data;
	}

	async getTransporteursBySite(id) {
		const url = `${__configuration.apiUrl}/portail/shared-data/transporteur/${id}`;
		const res = await this.$http.get(url);

		return res.data;
	}

	async getChantiersBySite(id) {
		const url = `${__configuration.apiUrl}/portail/shared-data/chantier/${id}`;
		const res = await this.$http.get(url);

		return res.data;
	}

	async getDapAttenteFileById(id) {
		const url = `${serviceUrl}/attente/get-file/${id}`;
		const res = await this.$http.get(url, {
			responseType: 'arraybuffer'
		});

		return res;
	}

	async deleteDAPAttente(id) {
		const res = await this.$http.get(`${serviceUrl}/attente/delete/${id}`);

		return res.data;
	}

	async createDAP(dap) {
		const data = {
			numero: dap.entete.numero,
			dateCreation: dap.entete.dateCreation,
			dateValidite: dap.entete.dateValidite,
			etat: dap.entete.etat,
			signatureTransporteur: dap.entete.signatureTransporteur,
			signatureClient: dap.entete.signatureClient,
			signatureProducteurDechet: dap.entete.signatureProducteurDechet,
			quantite: dap.entete.quantite,
			siteCommercialId: dap.entete.siteCommercialId,
			producteurId: dap.entete.producteurId,
			producteurDechetId: dap.entete.producteurDechetId,
			nomenclatureDechetId: dap.entete.nomenclatureDechetId,
			transporteurIds: dap.entete.transporteurIds,
			clientDetenteurId: dap.entete.clientDetenteurId,
			chantierOrigineId: dap.entete.chantierOrigineId,
			lieuOrigineId: dap.entete.lieuOrigineId,
			controles: dap.entete.controles,
			controlesIds: dap.entete.controlesIds,
			nomenclatureIds: dap.entete.nomenclatureIds,
			transporteurSignatures: dap.entete.transporteurSignatures,
			ecoOrganisme: dap.entete.ecoOrganisme,
			longitude: dap.entete.longitude,
			latitude: dap.entete.latitude
		};

		const result = await this.$http.post(serviceUrl, data);

		return result.data;
	}

	async validateDap(dap) {
		console.log('🔥: validateDap -> dap', dap);
	}

	async rejectDap(dap) {
		console.log('🔥: rejectDap -> dap', dap);
	}

	async updateDAP(dap) {
		const data = dap.entete;
		console.log('DAP UPDATE ', dap, data);
		return await this.$http.put(serviceUrl, data);
	}

	async codeExists(code) {
		const url = `${serviceUrl}/code-unicity/${code}`;
		const result = await this.$http.get(url);
		return result.data;
	}

	async print(id, sendToPortail, fileName) {
		const url = `${__configuration.apiUrl}/massia/gestion/dap/${id}/print`;
		const res = await this.$http.post(
			url,
			{ fileName: fileName, sendToPortail: sendToPortail },
			{
				responseType: 'arraybuffer'
			}
		);
		return res;
	}

	async accuseAcceptation(id, date) {
		const exportData = { id: JSON.stringify(id), date: JSON.stringify(date) };
		const url = `${__configuration.apiUrl}/massia/gestion/dap/accuse-acceptation`;
		const res = await this.$http.get(url, {
			params: exportData,
			responseType: 'arraybuffer'
		});
		return res;
	}

	async getNomenclatures() {
		const url = `${__configuration.apiUrl}/massia/nomenclature/headers`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getDapData(type, ids) {
		const request = {
			typeData: type,
			ids: ids
		};

		const url = `${serviceUrl}/attente/get-data`;
		const res = await this.$http.post(url, request);
		return res.data;
	}

	async getSitesCommerciaux(valeur) {
		const url = `${__configuration.apiUrl}/massia/sites-commerciaux/typeAhead/${valeur}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getTransporteurs(idSiteCommercial) {
		const url = `${__configuration.apiUrl}/massia/sites-transporteurs/typeAheadCompteOuvertSiteComm/${idSiteCommercial}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getLieux(valeur, idClient) {
		const url = `${__configuration.apiUrl}/massia/lieux/typeAhead/${valeur}/${idClient}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getProducteurs(valeur, idSiteCommercial) {
		const url = `${__configuration.apiUrl}/massia/sites-producteurs/typeAheadCompteOuvertSiteComm/${idSiteCommercial}/${valeur}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getClients(valeur, idSiteCommercial, withFournisseur = false) {
		const url = `${__configuration.apiUrl}/massia/sites-clients/typeAheadCompteOuvertSiteComm/${idSiteCommercial}/${valeur}/${withFournisseur}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getChantiers(valeur, idClient) {
		const url = `${__configuration.apiUrl}/massia/chantiers/headersByClient/${idClient}/${valeur}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getControles() {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/prestation-controles`);
		return res.data;
	}

	async estCodeAuto() {
		const url = `${serviceUrl}/est-code-auto`;
		try {
			const res = await this.$http.get(url);
			return res.data;
		} catch (err) {
			if (err.status === 404) {
				return false;
			}
			throw err;
		}
	}

	async getEtats() {
		const url = 'assets/enum/daps/etats.json';
		const res = await this.$http.get(url);
		return res.data;
	}

	async exportListeDaps(modeImpr, filters, sorts) {
		const url = `${serviceUrl}/print`;
		const res = await this.$http.get(url, {
			params: {
				modeImpr: JSON.stringify(modeImpr),
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || [])
			},
			responseType: 'arraybuffer'
		});
		return res;
	}

	async getProducteurDechets(valeur) {
		const url = `${__configuration.apiUrl}/massia/personnes/contacts/header/${valeur}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getLieuLocation(lieu) {
		const query = `${lieu.adresse || ''},${lieu.codePostal},${lieu.ville}`;
		const res = await this.$http.get('https://nominatim.openstreetmap.org/search?format=json&q=' + query);
		if (isArray(res.data)) {
			return res.data[0];
		}
		return res.data;
	}

	async getSocietesByType() {
		const type = 'ECO_ORGA';
		const url = `${__configuration.apiUrl}/massia/societes/by-type/${type}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async duplicateDap(id) {
		const url = `${__configuration.apiUrl}/massia/gestion/dap/${id}/duplicate`;
		const result = await this.$http.post(url);
		return result.data;
	}
}

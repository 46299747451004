import DapPrintModalController from './dap-print-modal.controller';
import DapPrintModalTemplate from './dap-print-modal.html';
export default {
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	},
	template: DapPrintModalTemplate,
	controller: DapPrintModalController
};

import ApiService from './api.service';
import ArretsService from './arrets.service';
import CoefficientkService from './coefficientk.service';
import ConfigurationBalanceService from './configuration.balance.service';
import DataShareService from './dataShare.service';
import FormulesService from './formules.service';
import FuseauxGranulometriquesService from './fuseaux.granulometriques.service';
import lifeCycleHelper from './lifeCycleHelper.service';
import MOPService from './mop.service';
import ParametreProgrammeService from './parametre.programme.service';
import ProcessusService from './processus.service';
import RsiService from './rsi.service';
import SocketService from './socket.service';
import svcQueryTimer from './svcQueryTimer.service';
import TamisService from './tamis.service';
import VersionService from './version.service';

const mName = 'massia.services';

angular
	.module(mName, [])
	.service('DataShareService', DataShareService)
	.service('FormulesService', FormulesService)
	.service('MOPService', MOPService)
	.service('ApiService', ApiService)
	.factory('svcQueryTimer', svcQueryTimer)
	.factory('lifeCycleHelper', lifeCycleHelper)
	.service('RsiService', RsiService)
	.service('CoefficientkService', CoefficientkService)
	.service('ParametreProgrammeService', ParametreProgrammeService)
	.service('ArretsService', ArretsService)
	.service('ProcessusService', ProcessusService)
	.service('ConfigurationBalanceService', ConfigurationBalanceService)
	.service('SocketService', SocketService)
	.service('FuseauxGranulometriquesService', FuseauxGranulometriquesService)
	.service('TamisService', TamisService)
	.service('VersionService', VersionService);

export default mName;

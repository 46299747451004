import CommandePopupController from './commande.popup.controller';
import commandePopup from './commande.popup.html';

export default {
	bindings: {
		modalInstance: '=',
		idCommande: '=',
		paiement: '='
	},
	template: commandePopup,
	controller: CommandePopupController
};

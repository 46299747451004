export default class StocksConfigurationProduitsService {
	static $inject = ['$http'];

	constructor($http) {
		this.$http = $http;
	}

	async getSitesCommerciauxSansSociete() {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-commerciaux/headers`);
		return res.data;
	}

	async getProduitsStocksElements() {
		let url = `${__configuration.apiUrl}/massia/domaines/produitsstockes/elements-hierarchiques`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getProduitsBySiteAndNiveau(idSite, idNiveau) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/produits/sitecom/${idSite}/niveau/${idNiveau}`);
		return res.data;
	}

	async updateProduitsStock(idSite, data) {
		const res = await this.$http.put(`${__configuration.apiUrl}/massia/produits/sitecom/${idSite}/stock`, data);
		return res.data;
	}

	async getProduitsCiblesGroupeSiteBySite(idSite) {
		let url = `${__configuration.apiUrl}/massia/produits/site/${idSite}/produits-cibles-groupe-site`;
		let res = await this.$http.get(url);
		console.log('🔥: StocksConfigurationProduitsService -> getProduitsCiblesGroupeSiteBySite -> res.data', res.data);
		return res.data;
	}
}

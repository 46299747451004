import angular from 'angular';

//Config
import StocksConfigurationProduitsNiveauxConfig from './_config/stocks.configuration.produits.niveaux.config';
import StocksConfigurationProduitsNiveauxRoutes from './_config/stocks.configuration.produits.niveaux.routes';

//Components
import StocksConfigurationProduitsNiveaux from './components/stocks-configuration-produits-niveaux';

//services
import StocksConfigurationProduitsNiveauxService from './services/stocks.configuration.produits.niveaux.service';

const moduleName = 'app.massia.gestion.stocks.configuration.produits.niveaux';

angular
	.module(moduleName, [])
	.config(StocksConfigurationProduitsNiveauxConfig)
	.config(StocksConfigurationProduitsNiveauxRoutes)
	.service('StocksConfigurationProduitsNiveauxService', StocksConfigurationProduitsNiveauxService)
	.component('stocksConfigurationProduitsNiveaux', StocksConfigurationProduitsNiveaux);

export default moduleName;

const serviceUrl = `${__configuration.apiUrl}/massia/template-impression`;
//import isElectron from 'is-electron';

export default class TemplateImpressionService {
	constructor($http, $uibModal) {
		this.$http = $http;
		this.$uibModal = $uibModal;
	}

	async getTemplateList(filters, sorts, pagination) {
		const res = await this.$http.get(serviceUrl, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {})
			}
		});

		return res.data;
	}

	async upload(data) {
		const formData = new FormData();
		formData.append('info', angular.toJson(data.data));
		formData.append('file', data.file);
		const res = await this.$http.post(serviceUrl + '/upload', formData, {
			transformRequest: angular.identity,
			headers: {
				'Content-Type': undefined
			}
		});
		return res.data;
	}

	async update(data) {
		const formData = new FormData();
		data.data.Size = 0;
		formData.append('info', angular.toJson(data.data));
		formData.append('file', data.file);
		const res = await this.$http.put(serviceUrl + '/update', formData, {
			transformRequest: angular.identity,
			headers: {
				'Content-Type': undefined
			}
		});
		return res.data;
	}

	async checkCode(code) {
		try {
			await this.$http.get(serviceUrl + '/code/' + code);
			return true;
		} catch (error) {
			return false;
		}
	}

	async deleteTemplateById(id) {
		const url = `${serviceUrl}/${id}`;
		return this.$http.delete(url);
	}

	async getTemplateById(id) {
		const url = `${serviceUrl}/${id}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getTemplateByDomaine(domaine) {
		const url = `${serviceUrl}/domaine/${domaine}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async downloadOriginalTemplate(template) {
		const link = document.createElement('a');
		link.id = 'downloadlink';
		link.href = `${__server}/templates/${template}`;
		link.target = '_blank';
		link.download = template;
		document.getElementsByTagName('body')[0].append(link);
		link.click();
		document.getElementById('downloadlink').remove();
	}

	async downloadTemplate(output, template, domaine) {
		try {
			const fileOriginal = template;
			// console.log("🚀 ~ file: template.impression.service.js ~ line 99 ~ TemplateImpressionService ~ downloadTemplate ~ fileOriginal", fileOriginal)
			const ext = fileOriginal.extension.replace('.', '');
			const url = `${serviceUrl}/download/${this.getFilename(output)}`;
			const res = await this.$http.get(url, { responseType: 'arraybuffer', params: { extension: ext, pdf: template.pdf ? true : false } });
			const file = new Blob([res.data], {
				type: 'application/octet-stream'
			});
			const link = document.createElement('a');
			link.style.opacity = 0;
			link.style.height = 0;
			const fileUrl = window.URL.createObjectURL(file);
			const result = `${domaine}_${Date.now()}.${template.pdf ? '.pdf' : ext}`;
			/*if(isElectron()){
                const electron = require('electron');
                try {
                    await this.downloadFile({
                        file: res.data,
                        path: electron.remote.app.getPath('downloads') + result
                    });
                    electron.remote.shell.openItem(electron.remote.app.getPath('userData') + '\\tmp\\' + result);
                } catch (err) {
                    console.warn(err);
                }
            } else {*/
			link.download = result;
			link.href = fileUrl;
			link.target = '_blank';
			link.id = 'downloadlink';
			document.getElementsByTagName('body')[0].append(link);
			link.click();
			document.getElementById('downloadlink').remove();
			//}
			return result;
		} catch (error) {
			console.log('🚀 ~ file: template.impression.service.js ~ line 132 ~ TemplateImpressionService ~ downloadTemplate ~ error', error);
		}
	}

	async downloadTemplateElectron(output, template, domaine, progress) {
		const fileOriginal = template;
		const ext = fileOriginal.extension.replace('.', '');
		const url = `${serviceUrl}/download/${this.getFilename(output)}`;
		const res = await this.$http.get(url, {
			responseType: 'arraybuffer',
			params: { extension: ext, pdf: template.pdf ? true : false },
			eventHandlers: {
				progress: progress
			}
		});
		const result = `${domaine}_${Date.now()}.${template.pdf ? '.pdf' : ext}`;
		return {
			filename: result,
			file: {
				length: res.headers('Content-length'),
				file: res.data
				//path: require('path').join(require('electron').remote.app.getPath('downloads'), result),
			}
		};
	}

	async downloadModal(
		domaine,
		displayPdfOption = true,
		displayPortailOption = false,
		html = null,
		resolver = {},
		customFilename = '',
		portail = false,
		filenameauto = false,
		displayGroupProp = true
	) {
		const modalInstance = this.$uibModal.open({
			animation: true,
			size: 'md',
			component: 'massiaPrintModal',
			backdrop: false,
			resolve: {
				domaine: () => {
					return domaine;
				},
				displayPdfOption: () => {
					return displayPdfOption;
				},
				displayPortailOption: () => {
					return displayPortailOption;
				},
				displayGroupProp: () => {
					return displayGroupProp;
				},
				customFilename: () => {
					return customFilename;
				},
				portail: () => {
					return portail;
				},
				groupLinkedProp: () => {
					return displayGroupProp;
				},
				nameAuto: () => {
					return filenameauto;
				},
				html: () => html,
				...resolver
			}
		});
		try {
			const res = await modalInstance.result;
			return res;
		} catch (err) {
			return err;
		}
	}

	getFilename(file) {
		const ext = ['.docx', '.xlsx', '.DOCX', '.XLSX', '.xlsm', '.XLSM'];
		let temp = file;
		for (let i = 0; i < ext.length; i++) {
			temp = temp.replace(ext[i], '');
		}
		return temp;
	}
}

TemplateImpressionService.$inject = ['$http', '$uibModal'];

import _ from 'lodash';
const serviceUrl = `${__configuration.apiUrl}/massia/personnes`;

export default class PersonnesService {
	static $inject = ['$http'];

	constructor($http) {
		this.$http = $http;
	}

	async getPersonnes(isEnabled, genre, filters, sorts, pagination, application) {
		let url = `${serviceUrl}/enabled`;
		if (!isEnabled) {
			url = `${serviceUrl}/disabled`;
		}
		const res = await this.$http.get(url, {
			params: {
				genre: genre,
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || { skip: 0, take: 0 }),
				application: JSON.stringify(application)
			}
		});

		return res.data;
	}

	async getPersonnesHeaders() {
		const url = `${serviceUrl}/header`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async deletePersonneById(id) {
		const url = `${serviceUrl}/${id}`;
		return this.$http.delete(url);
	}

	async getPersonneById(id) {
		const url = `${serviceUrl}/${id}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getPersonneTypes() {
		const id = 'Personnel';
		const url = `${__configuration.apiUrl}/massia/domaines/${id}/types`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getSocietes(personne) {
		if (personne.isChauffeur && personne.isPersonne) {
			const url = `${__configuration.apiUrl}/massia/societes-commercial-fournisseur-transporteur/headers`;
			const res = await this.$http.get(url);
			return res.data;
		}
		/*let isCommercialOuFournisseur  = (personne.isContact  && !personne.isChauffeur) || personne.isPersonne;
            let isClientOuProducteur = personne.isContact && !(personne.isPersonne || personne.isChauffeur);
            let isTransporteur =  (personne.isContact  && !personne.isPersonne) || personne.isChauffeur;
            if(!isCommercialOuFournisseur && !isClientOuProducteur && !isTransporteur)
            {
                switch (genre) {
                    case "personne":
                        isCommercialOuFournisseur=true;
                        break;
                    case "chauffeur":
                        isTransporteur=true;
                        break;
                }
            }*/

		const url = `${__configuration.apiUrl}/massia/societes/header-genre`;
		const res = await this.$http.get(url, {
			params: {
				/*isCommercial: isCommercialOuFournisseur,
                    isFournisseur: isCommercialOuFournisseur,
                    isClient: isClientOuProducteur,
                    isTransporteur: isTransporteur,
                    isProducteur: isClientOuProducteur*/
				isCommercial: personne.isPersonne || personne.isContact,
				isFournisseur: personne.isPersonne || personne.isContact,
				isClient: personne.isContact,
				isTransporteur: personne.isContact || personne.isChauffeur,
				isProducteur: personne.isPersonne || personne.isContact,
				isLaboratoire: personne.isPersonne || personne.isContact,
				isProprietaire: personne.isContact,
				isLocataire: personne.isContact,
				isAuditeur: personne.isPersonne || personne.isContact
			}
		});
		return res.data;
	}

	async getSitesBySociete(personne) {
		const id = personne.idSociete;
		if (personne.isChauffeur || personne.isPersonne || personne.isContact) {
			if (personne.isChauffeur && personne.isPersonne) {
				const url = `${__configuration.apiUrl}/massia/sites-commerciaux-fournisseurs-transporteurs/header/${id}`;
				const res = await this.$http.get(url);
				return res.data;
			}
			/*let isCommercialOuFournisseur = (personne.isContact  && !personne.isChauffeur) || personne.isPersonne;
                let isClientOuProducteur = personne.isContact && !(personne.isPersonne || personne.isChauffeur);
                let isTransporteur =  (personne.isContact  && !personne.isPersonne) || personne.isChauffeur;*/

			const url = `${__configuration.apiUrl}/massia/sites/header/${id}`;
			const res = await this.$http.get(url, {
				params: {
					/*isCommercial: isCommercialOuFournisseur,
                        isFournisseur: isCommercialOuFournisseur,
                        isClient: isClientOuProducteur,
                        isTransporteur: isTransporteur,
                        isProducteur: isClientOuProducteur*/
					isCommercial: personne.isPersonne || personne.isContact,
					isFournisseur: personne.isPersonne || personne.isContact,
					isClient: personne.isContact,
					isTransporteur: personne.isContact || personne.isChauffeur,
					isProducteur: personne.isPersonne || personne.isContact,
					isLaboratoire: personne.isPersonne || personne.isContact,
					isProprietaire: personne.isContact,
					isLocataire: personne.isContact,
					isAuditeur: personne.isPersonne || personne.isContact
				}
			});
			return res.data;
		}
		return undefined;
	}

	async getSiteDeparts() {
		const url = `${__configuration.apiUrl}/massia/sites/header-genre`;
		const res = await this.$http.get(url, {
			params: {
				isCommercial: true,
				isFournisseur: false,
				isClient: false,
				isTransporteur: true,
				isProducteur: false,
				isLaboratoire: false,
				isProprietaire: false,
				isLocataire: false,
				isAuditeur: false
			}
		});
		return res.data;
	}

	async getSites(personne) {
		const url = `${__configuration.apiUrl}/massia/sites/header-genre`;
		const res = await this.$http.get(url, {
			params: {
				isCommercial: personne.isPersonne || personne.isContact,
				isFournisseur: personne.isPersonne || personne.isContact,
				isClient: personne.isContact,
				isTransporteur: personne.isContact || personne.isChauffeur,
				isProducteur: personne.isPersonne || personne.isContact,
				isLaboratoire: personne.isPersonne || personne.isContact,
				isProprietaire: personne.isContact,
				isLocataire: personne.isContact,
				isAuditeur: personne.isPersonne || personne.isContact
			}
		});
		return res.data;
	}

	async getSignatures() {
		const url = `${__configuration.apiUrl}/massia/images/signature`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getAffectations(idSite) {
		const url = `${__configuration.apiUrl}/massia/performance/affectations/header/${idSite}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getPersonneValeursCaracteristiquesAssociationsById(id) {
		const data = await this.getPersonneValeursCaracteristiquesById(id);
		const result = {};

		for (const element in data) {
			if (data[element] && !Array.isArray(data[element])) {
				if (data[element].value === 'True') {
					data[element].value = 'true';
				}
				if (data[element].value === 'False') {
					data[element].value = 'false';
				}

				if (data[element].value && data[element].value.key) {
					result[data[element].id] = data[element].value.key;
				} else {
					result[data[element].id] = data[element].value;
				}
			}
		}
		return result;
	}

	async getPersonneValeursCaracteristiquesById(id) {
		const url = `${serviceUrl}/${id}/valeurs-caracteristiques`;
		const res = await this.$http.get(url);
		const result = [];
		res.data.forEach(function (element) {
			const caract = {
				id: element.id,
				code: element.code,
				idNature: element.idNature,
				indice: element.indice,
				label: element.label,
				numericAdditionalInformation: element.numericAdditionalInformation,
				value: element.elementsValues || element.elementValue || element.values || element.value
			};
			result.push(caract);
		});
		return result;
	}

	async createPersonne(personne, nomApplication) {
		const caractTemp = this.formatCaracteristiques(personne.caracteristiques);
		const data = personne;
		data.caracteristiques = [];
		data.caracteristiques = caractTemp;
		data.appName = nomApplication;
		const result = await this.$http.post(serviceUrl, data);
		return result.data;
	}

	formatCaracteristiques(caracteristiques) {
		const data = [];
		for (const property in caracteristiques) {
			let keyValueObject;
			//On vérifie que la propriété ne commence par pas '$' qui est normalement réservé aux variables interne Js.
			if (property[0] !== '$' && property !== 'data' && !isNaN(property)) {
				const propertyVal = caracteristiques[property];
				if (Array.isArray(propertyVal)) {
					if (_.some(propertyVal, 'key')) {
						keyValueObject = {
							IdCaracteristique: parseInt(property),
							valeurs: _.map(propertyVal, 'key')
						};
					} else {
						keyValueObject = {
							IdCaracteristique: parseInt(property),
							valeurs: propertyVal
						};
					}
				} else {
					keyValueObject = {
						IdCaracteristique: parseInt(property),
						valeur: propertyVal
					};
				}
				data.push(keyValueObject);
			}
		}
		return data;
	}

	async updatePersonne(personne, nomApplication) {
		console.log(personne);
		const caractTemp = this.formatCaracteristiques(personne.caracteristiques);
		const data = personne;
		data.caracteristiques = [];
		data.caracteristiques = caractTemp;
		data.appName = nomApplication;
		const url = `${serviceUrl}/${personne.id}`;
		return this.$http.put(url, data);
	}

	async updatePassword(idUtilisateur, newPassword) {
		return this.$http.put(`${serviceUrl}/${idUtilisateur}/password`, {
			password: newPassword
		});
	}

	async activatePersonne(id) {
		const url = `${serviceUrl}/activate/${id}`;
		return this.$http.put(url);
	}

	async codeExists(code) {
		const url = `${serviceUrl}/code-unicity/${code}`;
		const result = await this.$http.get(url);
		return result.data;
	}

	async loginExists(userId, login) {
		let url = `${__configuration.apiUrl}/massia/personnes?login=${login}`;
		if (userId) {
			url += `&Id=${userId}`;
		}

		try {
			await this.$http.head(url);
			return true;
		} catch (err) {
			if (err.status === 404) {
				return false;
			}
			throw err;
		}
	}

	async getAllProfils(nomApplication) {
		const url = `${__configuration.apiUrl}/massia/profils`;
		const res = await this.$http.get(url, {
			params: {
				appName: JSON.stringify(nomApplication || null)
			}
		});
		return res.data;
	}

	async exportListePersonnes(modeImpr, filters, sorts) {
		const url = `${serviceUrl}/print`;
		const res = await this.$http.get(url, {
			params: {
				modeImpr: JSON.stringify(modeImpr),
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || [])
			},
			responseType: 'arraybuffer'
		});
		return res;
	}

	async getAllUsers(type = {}) {
		const url = `${serviceUrl}/all-users`;
		const res = await this.$http.get(url, {
			params: {
				type: type
			}
		});
		return res.data;
	}

	async getContactsBySite(idSite) {
		const siteUrl = `${serviceUrl}/contacts-by-site/headers/${idSite}`;
		const res = await this.$http.get(siteUrl);

		return res.data;
	}

	async getUtilisateurs() {
		const personneUrl = `${serviceUrl}/utilisateurs`;
		const res = await this.$http.get(personneUrl);
		return res.data;
	}

	async updateFiltreUtilisateur(data, application) {
		data.application = application;
		return this.$http.put(`${__configuration.apiUrl}/massia/filtres-utilisateur`, data);
	}
}

import UtilisateurPasswordController from '../../../../common/personnes/components/utilisateur-password-form/utilisateur.password.form.controller';

export default class UserInfoController {
	/**
	 *
	 * @param {import('angular').IScope} $scope
	 * @param {import('angular').IRootScopeService} $rootScope
	 * @ngInject
	 */
	constructor(
		$rootScope,
		$scope,
		$http,
		MassiaAuthService,
		MassiaNavigationService,
		MassiaNavigationConfigConstant,
		MassiaApplicationService,
		ModalService,
		AuthConstant,
		localStorageService,
		MassiaClientService,
		globalizationManagementService,
		$window,
		$translate,
		SitesService,
		VersionService
	) {
		this.$rootScope = $rootScope;
		this.$scope = $scope;
		this.$http = $http;
		this.MassiaAuthService = MassiaAuthService;
		this.MassiaNavigationService = MassiaNavigationService;
		this.MassiaNavigationConfigConstant = MassiaNavigationConfigConstant;
		this.MassiaApplicationService = MassiaApplicationService;
		this.ModalService = ModalService;
		this.AuthConstant = AuthConstant;
		this.localStorageService = localStorageService;
		this.MassiaClientService = MassiaClientService;
		this.globalizationManagementService = globalizationManagementService;
		this.$window = $window;
		this.$translate = $translate;
		this.SitesService = SitesService;
		this.versionSvc = VersionService;
		this.selectedCulture = this.globalizationManagementService.getCurrentLanguage();
		this.allCultures = this.globalizationManagementService.getAvailableLanguages();
		this.allCultures.find((e) => e.code === this.selectedCulture.code).selected = true;
	}

	async $onInit() {
		this.licenceNumber = __configuration.licenceGest;
		this.loading = false;
		this.getNavigationFavorites();
		this.getHelp();
		this.user = await this.MassiaAuthService.getAuthenticatedUser();
		this.prefLanguageActivated = __configuration.preferredLanguage ? true : false;
		this.versionSvc.getVersion().then((version) => {
			this.version = version;
			this.MassiaNavigationConfigConstant.help.items.find((x) => x.id === 'help_version').translate = version;
		});
		this.application = this.MassiaApplicationService.getApplication();

		if (this.application === 'performance') {
			this.getSitesProd();

			this.$rootScope.$on('userContextChange', (e) => {
				const localUser = this.localStorageService.get(this.AuthConstant.MASSIA_PERSISTS_USER_DATA_STORAGE);
				this.selectedNavSite = localUser.selectedNavSite || { id: 0, libelle: '' };
			});
		}
	}

	logout() {
		const synchroDatas = this.localStorageService.get(this.AuthConstant.MASSIA_WAITING_DATA_STORAGE);
		if (synchroDatas && Object.keys(synchroDatas).length > 0 && synchroDatas.constructor === Object) {
			if (confirm("L'archivage des factures est en cours, êtes vous sur de vouloir vous déconnecter ?")) {
				// on supprime les données de synchro
				this.localStorageService.remove(this.AuthConstant.MASSIA_WAITING_DATA_STORAGE);
				this.MassiaAuthService.logout();
			}
		} else {
			this.MassiaAuthService.logout();
		}
	}

	getNavigationFavorites() {
		/*this.startLoading();
        // TODO passer dans un service
        this.$http.get('mock/favorites.json')
            .then((response) => {
                this.setNavigationFavorites(response.data);
            })
            .finally(() => this.stopLoading());*/
	}

	setNavigationFavorites(favorites) {
		if (favorites && favorites.length) {
			const items = favorites.map((favorite = {}) => {
				return {
					id: favorite.label,
					translate: favorite.label,
					header: !!favorite.items,
					sref: favorite.action,
					items: (favorite.items || []).map((item) => {
						return {
							id: item.label,
							translate: item.label,
							sref: item.action
						};
					})
				};
			}, this);

			this.MassiaNavigationService.addItem({
				id: 'favorite',
				icon: 'massia-icon-star',
				translate: 'LAYOUT.NAVIGATION.FAVORIS',
				translateCls: 'visible-xs-inline',
				position: 'right',
				order: 0,
				items: items
			});
		}
	}

	getHelp() {
		this.helpMenu = this.MassiaNavigationConfigConstant.help;
		this.helpMenu.items = this.helpMenu.items.filter((hlpMenu) => {
			return (
				hlpMenu &&
				// filtre des accès par langue
				(hlpMenu.lng === undefined || hlpMenu.lng === this.selectedCulture.code)
			);
		});

		if (this.MassiaClientService.getClient()) {
			this.helpMenu.items = [this.helpMenu.items[0]];
		}
	}

	updatePassword() {
		const id = this.user.id;
		const nom = this.user.nom;
		const prenom = this.user.prenom;
		this.ModalService.open({
			template: require('../../../../common/personnes/components/utilisateur-password-form/utilisateur.password.form.html'),
			controller: UtilisateurPasswordController,
			controllerAs: '$ctrl',
			size: 'lg',
			resolve: {
				data: function () {
					return {
						id: id,
						nom: nom,
						prenom: prenom
					};
				}
			}
		});
	}

	changeCulture(culture) {
		localStorage.setItem('culture', culture);
		this.globalizationManagementService.setCurrentLanguage(culture);
		this.$window.location.reload();
	}

	async getSitesProd() {
		this.sitesProdLoaded = await this.SitesService.getSitesProducteursByApplication('performance');
		this.sitesProdLoaded.splice(0, 0, { id: 0, libelle: this.$translate.instant('LAYOUT.USER_SITE') });

		const localUser = this.localStorageService.get(this.AuthConstant.MASSIA_PERSISTS_USER_DATA_STORAGE);
		this.selectedNavSite = localUser.selectedNavSite || { id: 0, libelle: '' };
	}

	async clickSite(ev) {
		if (ev) {
			ev.stopPropagation();
			ev.stopImmediatePropagation();
			ev.preventDefault();
		}
	}

	async changeSite(ev) {
		const localUser = this.localStorageService.get(this.AuthConstant.MASSIA_PERSISTS_USER_DATA_STORAGE);

		const selectedNavSite = this.sitesProdLoaded.find((e) => e.id == this.selectedNavSite.id);
		if (!selectedNavSite || selectedNavSite.id === 0) {
			delete localUser.selectedNavSite;
		} else {
			localUser.selectedNavSite = selectedNavSite;
			localUser.selectedNavSite.keepProperty = true;
		}

		this.localStorageService.set(this.AuthConstant.MASSIA_PERSISTS_USER_DATA_STORAGE, localUser);
		this.$rootScope.$broadcast('userContextChange');
	}

	removeSite(ev) {
		if (ev) {
			ev.stopPropagation();
			ev.stopImmediatePropagation();
			ev.preventDefault();
		}

		const localUser = this.localStorageService.get(this.AuthConstant.MASSIA_PERSISTS_USER_DATA_STORAGE);
		delete localUser.selectedNavSite;

		this.selectedNavSite = { id: 0, libelle: '' };

		this.localStorageService.set(this.AuthConstant.MASSIA_PERSISTS_USER_DATA_STORAGE, localUser);
		this.$rootScope.$broadcast('userContextChange');
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}
}

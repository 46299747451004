/*@ngInject*/
export default class PortailHomeController {
	_navigationList = [];

	constructor(MassiaApplicationService, UserRequestService, $scope, configPortail, MOPService) {
		this.MassiaApplicationService = MassiaApplicationService;
		this.UserRequestService = UserRequestService;
		this.$scope = $scope;
		this.configPortail = configPortail;
		this.MOPService = MOPService;
	}
	async $onInit() {
		this.MOPService.setMop([{ title: 'Portail Massia', filename: 'PortailMassia.pdf', application: 'massia' }]);
		this.close = false;

		this.UserRequestService.loadAll();

		this.$scope.$watch(
			() => this.UserRequestService.request,
			() => this.createNavList(),
			true
		);
		this.$scope.$watch(
			() => this.UserRequestService.userAddRequest,
			() => this.createNavList(),
			true
		);
	}

	createNavList() {
		const tmp = this.UserRequestService.request;
		const countAddReq = this.UserRequestService.userAddRequest;

		this._navigationList = [
			{
				libelle: 'PORTAIL.NAV.USERS',
				href: 'portail.users.list',
				active: 'portail.users.**',
				icon: 'glyphicon glyphicon-user'
			},
			// {
			//     libelle: 'PORTAIL.NAV.AFFECTATIONS',
			//     href: 'portail.affectation.list',
			//     active: 'portail.affectation.**',
			//     icon: 'glyphicon glyphicon-th-large',
			// },
			//* non utilisé pour le moment
			// {
			//     libelle: 'PORTAIL.NAV.GROUP',
			//     href: 'portail.group.list',
			//     active: 'portail.group.**',
			//     icon: 'glyphicon glyphicon-th-list',
			// },
			{
				libelle: 'PORTAIL.NAV.REQUEST',
				href: 'portail.request.nav',
				active: 'portail.request.**',
				icon: 'glyphicon glyphicon-edit',
				badge: tmp.length + countAddReq.length
			},
			{
				libelle: 'PORTAIL.NAV.DOC',
				href: 'portail.file.list',
				active: 'portail.file.**',
				icon: 'glyphicon glyphicon-file'
			},
			// {
			//     libelle: 'PORTAIL.NAV.USER_REQUEST',
			//     href: 'portail.user-add-request.list',
			//     active: 'portail.add-user-request.**',
			//     icon: 'glyphicon glyphicon-check',
			//     badge: countAddReq.length
			// },

			{
				libelle: 'PORTAIL.NAV.SETTING',
				icon: 'glyphicon glyphicon-cog',
				sub: [
					// {
					//     libelle: 'PORTAIL.NAV.SERVER',
					//     href: 'portail.setting.server',
					//     active: 'portail.setting.server.**',
					//     icon: 'glyphicon glyphicon-tasks',
					// },
					{
						libelle: 'PORTAIL.NAV.MAIL',
						href: 'portail.setting.mail',
						active: 'portail.setting.mail.**',
						icon: 'glyphicon glyphicon-comment'
					},
					{
						libelle: 'PORTAIL.NAV.DOC_SETTING',
						href: 'portail.setting.doc',
						active: 'portail.setting.doc.**',
						icon: 'glyphicon glyphicon-duplicate'
					},
					{
						libelle: 'PORTAIL.NAV.DISPLAY',
						href: 'portail.setting.display',
						active: 'portail.setting.display.**',
						icon: 'glyphicon glyphicon-adjust'
					}
				]
			}
		];
	}

	closeSide() {
		this.close = !this.close;
		const side = $('#nav-portail');
		const content = $('#ui-portail');
		const elementToHide = $('.close-nav');
		side.css('width', '50px');
		content.css({ 'margin-left': '50px' });
		for (let i = 0; i < elementToHide.length; i++) {
			const element = $(elementToHide[i]);
			element.css('width', '0');
			element.css('height', '0');
			element.css('display', 'none');
		}
	}

	openSide() {
		this.close = !this.close;
		const side = $('#nav-portail');
		const content = $('#ui-portail');
		const elementToHide = $('.close-nav');
		side.css('width', '300px');
		content.css({ 'margin-left': '300px' });
		setTimeout(() => {
			for (let i = 0; i < elementToHide.length; i++) {
				const element = $(elementToHide[i]);
				element.css('width', 'auto');
				element.css('height', 'auto');
				element.css('display', 'block');
			}
		}, 200);
	}

	toggleCollapse() {
		this.collapse = !this.collapse;
	}

	$onDestroy() {}
}

// PortailHomeController.$inject = ['MassiaApplicationService', 'UserRequestService', '$scope', 'configPortail', 'MOPService'];

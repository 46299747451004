const serviceUrl = `${__configuration.apiUrl}/massia/chantiers`;
import * as _ from 'lodash';
export default class ChantiersService {
	static $inject = ['$http'];

	constructor($http) {
		this.$http = $http;
	}

	async getChantiers(filters, sorts, pagination) {
		const res = await this.$http.get(serviceUrl, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {})
			}
		});

		return res.data;
	}

	async deleteChantierById(id) {
		const url = `${serviceUrl}/${id}`;
		return this.$http.delete(url);
	}

	async getChantierEnteteById(id) {
		const url = `${serviceUrl}/${id}/entete`;
		const res = await this.$http.get(url);
		return res.data;
	}

	// TODO SL a supprimer quand ca fonctionnera dans pays
	async getAllPayss() {
		const url = `${__configuration.apiUrl}/massia/pays`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getChantierValeursCaracteristiquesAssociationsById(id) {
		const data = await this.getChantierValeursCaracteristiquesById(id);
		const result = {};

		for (const element in data) {
			if (data[element] && !Array.isArray(data[element])) {
				if (data[element].value === 'True') {
					data[element].value = 'true';
				}
				if (data[element].value === 'False') {
					data[element].value = 'false';
				}

				if (data[element].value && data[element].value.key) {
					result[data[element].id] = data[element].value.key;
				} else {
					result[data[element].id] = data[element].value;
				}
			}
		}
		return result;
	}

	async getChantierValeursCaracteristiquesById(id) {
		const url = `${serviceUrl}/${id}/valeurs-caracteristiques`;
		const res = await this.$http.get(url);
		const result = [];

		res.data.forEach(function (element) {
			const caract = {
				id: element.id,
				code: element.code,
				idNature: element.idNature,
				indice: element.indice,
				label: element.label,
				numericAdditionalInformation: element.numericAdditionalInformation,
				value: element.elementsValues || element.elementValue || element.values || element.value
			};
			result.push(caract);
		});

		return result;
	}

	async createChantier(chantier) {
		const data = {
			//id: entete.id,
			code: chantier.entete.code,
			libelle: chantier.entete.libelle,
			dateDebut: chantier.entete.dateDebut,
			dateFin: chantier.entete.dateFin,
			adresse: chantier.entete.adresse,
			codePostal: chantier.entete.codePostal,
			ville: chantier.entete.ville,
			telephone: chantier.entete.telephone,
			fax: chantier.entete.fax,
			referenceChantierClient: chantier.entete.referenceChantierClient,
			commentaireLivraison: chantier.entete.commentaireLivraison,
			// TODO SL probleme avec 0 qui semble etre un champs vide...
			etat: '0', // entete.etat,
			idType: chantier.entete.typeId,
			idPays: chantier.entete.idPays || null,
			idSite: chantier.entete.objetSiteClient ? chantier.entete.objetSiteClient.id : chantier.entete.idSite, //chantier.entete.nomSite.id,
			idSociete: chantier.entete.objetSociete ? chantier.entete.objetSociete.id : null,
			idLieu: chantier.entete.idLieu || null,
			objetSiteClient: chantier.entete.objetSiteClient,
			objetSociete: chantier.entete.objetSociete,
			caracteristiques: [],
			idContact: chantier.entete.idContact,
			isReferenceBasias: chantier.entete.isReferenceBasias,
			isReferenceBasol: chantier.entete.isReferenceBasol,
			isContamine: chantier.entete.isContamine,
			isPotentielContamine: chantier.entete.isPotentielContamine,
			isHeritageFromClient: chantier.entete.isHeritageFromClient,
			isDuBatiment: chantier.entete.isDuBatiment,
			coordGeo: chantier.entete.coordGeo
		};
		data.coordGeo.id = data.coordGeo.id === null ? 0 : data.coordGeo.id;
		data.caracteristiques = this.formatCaracteristiques(chantier.caracteristiques);
		const result = await this.$http.post(serviceUrl, data);
		return result.data;
	}

	formatCaracteristiques(caracteristiques) {
		const data = [];
		for (const property in caracteristiques) {
			let keyValueObject;
			//On vérifie que la propriété ne commence par pas '$' qui est normalement réservé aux variable interne Js.
			if (property[0] !== '$' && property !== 'data' && !isNaN(property)) {
				const propertyVal = caracteristiques[property];
				if (Array.isArray(propertyVal)) {
					if (_.some(propertyVal, 'key')) {
						keyValueObject = {
							IdCaracteristique: parseInt(property),
							valeurs: _.map(propertyVal, 'key')
						};
					} else {
						keyValueObject = {
							IdCaracteristique: parseInt(property),
							valeurs: propertyVal
						};
					}
				} else {
					keyValueObject = {
						IdCaracteristique: parseInt(property),
						valeur: propertyVal
					};
				}
				data.push(keyValueObject);
			}
		}
		return data;
	}

	async updateChantier(chantier) {
		console.log('🚀 ~ file: chantiers.service.js:152 ~ ChantiersService ~ updateChantier ~ chantier:', chantier);
		const data = {
			id: chantier.entete.id,
			code: chantier.entete.code,
			libelle: chantier.entete.libelle,
			dateDebut: chantier.entete.dateDebut,
			dateFin: chantier.entete.dateFin,
			adresse: chantier.entete.adresse,
			codePostal: chantier.entete.codePostal,
			ville: chantier.entete.ville,
			telephone: chantier.entete.telephone,
			fax: chantier.entete.fax,
			referenceChantierClient: chantier.entete.referenceChantierClient,
			commentaireLivraison: chantier.entete.commentaireLivraison,
			// TODO SL probleme avec 0 qui semble etre un champs vide...
			etat: '0', // chantier.etat,
			idType: chantier.entete.typeId,
			idPays: chantier.entete.idPays,
			idSite: chantier.entete.nomSite == null ? 2 : chantier.entete.nomSite.id,
			idLieu: chantier.entete.idLieu,
			idSociete: chantier.entete.objetSociete ? chantier.entete.objetSociete.id : null,
			objetSiteClient: chantier.entete.objetSiteClient,
			objetSociete: chantier.entete.objetSociete,
			caracteristiques: [],
			idContact: chantier.entete.idContact,
			isReferenceBasias: chantier.entete.isReferenceBasias,
			isReferenceBasol: chantier.entete.isReferenceBasol,
			isContamine: chantier.entete.isContamine,
			isPotentielContamine: chantier.entete.isPotentielContamine,
			isHeritageFromClient: chantier.entete.isHeritageFromClient,
			isDuBatiment: chantier.entete.isDuBatiment,
			coordGeo: chantier.entete.coordGeo
		};
		data.coordGeo.id = data.coordGeo.id === null ? 0 : data.coordGeo.id;
		data.caracteristiques = this.formatCaracteristiques(chantier.caracteristiques);

		const url = `${serviceUrl}/${data.id}`;
		return this.$http.put(url, data);
	}

	async updateChantierDates(chantier) {
		const data = {
			id: chantier.entete.id,
			dateDebut: chantier.entete.dateDebut,
			dateFin: chantier.entete.dateFin
		};

		const url = `${serviceUrl}/${data.id}/dates`;
		return this.$http.put(url, data);
	}

	async codeExists(code) {
		const url = `${serviceUrl}/code-unicity/${code}`;
		const result = await this.$http.get(url);
		return result.data;
	}

	async estCodeAuto() {
		const url = `${serviceUrl}/est-code-auto`;
		try {
			const res = await this.$http.get(url);
			return res.data;
		} catch (err) {
			if (err.status === 404) {
				return false;
			}
			throw err;
		}
	}

	async getNomSiteClients(valeur, isChantier = true) {
		if (isChantier) {
			const nomSiteClientUrl = `${__configuration.apiUrl}/massia/sites-clients/typeAhead/${valeur}`;
			const res = await this.$http.get(nomSiteClientUrl);
			return res.data;
		}
		const nomSiteClientUrl = `${__configuration.apiUrl}/massia/sites-fournisseurs/headers/${valeur}`;
		const res = await this.$http.get(nomSiteClientUrl);
		return res.data;
	}

	async getLieux(valeur, idClient) {
		const url = `${__configuration.apiUrl}/massia/lieux/typeAhead/${valeur}/${idClient}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getSiteById(id) {
		const url = `${__configuration.apiUrl}/massia/sites/${id}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getSocieteById(id) {
		const url = `${__configuration.apiUrl}/massia/societes/${id}/entete/gestion`;
		const res = await this.$http.get(url);
		return res.data;
	}
	// async getNomSiteClients(valeur, isChantier = true) {
	// 	if (isChantier) {
	// 		const nomSiteClientUrl = `${__configuration.apiUrl}/massia/sites-clients/typeAhead/${valeur}`;
	// 		const res = await this.$http.get(nomSiteClientUrl);
	// 		return res.data;
	// 	}
	// 	const nomSiteClientUrl = `${__configuration.apiUrl}/massia/sites-clients-fournisseurs/headers/${valeur}`;
	// 	const res = await this.$http.get(nomSiteClientUrl);
	// 	return res.data;
	// }

	async getProposition(id) {
		const url = `${__configuration.apiUrl}/massia/propositions/${id}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getClientById(id) {
		const genre = 1; // SL pour ne pas faire le traitement de recuperation des EtatCompteOuvert
		const application = 'gestion';
		const url = `${__configuration.apiUrl}/massia/sites/${id}/entete/genre/${genre}/${application}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async exportListeChantiers(modeImpr, isChantier, filters, sorts) {
		const url = `${serviceUrl}/print`;
		const res = await this.$http.get(url, {
			params: {
				modeImpr: JSON.stringify(modeImpr),
				isChantier,
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || [])
			},
			responseType: 'arraybuffer'
		});
		return res;
	}

	async getContactsBySite(idSite) {
		const siteUrl = `${__configuration.apiUrl}/massia/personnes/contacts-by-site/headers/${idSite}`;
		const res = await this.$http.get(siteUrl);

		return res.data;
	}

	async getChantierComposant(id, domaine) {
		const url = `${__configuration.apiUrl}/massia/chantier-composant/${id}/${domaine}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async exportChantierComposants(id, domaine) {
		const url = `${__configuration.apiUrl}/massia/chantier-composant/print/${id}/${domaine}`;
		const res = await this.$http.get(url, {
			params: {},
			responseType: 'arraybuffer'
		});
		return res;
	}

	async getChantiersByClients(idClient) {
		const url = `${serviceUrl}/headersByClient//${idClient}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getSocietes(valeur) {
		console.log('🚀 ~ file: chantiers.service.js:290 ~ ChantiersService ~ getSocietes ~ valeur:', valeur);
		const url = `${__configuration.apiUrl}/massia/societes-commerciales/typeAhead/${valeur}`;
		const res = await this.$http.get(url);
		console.log('🚀 ~ file: chantiers.service.js:293 ~ ChantiersService ~ getSocietes ~ res:', res.data);
		return res.data;
	}

	async getCompteOuverts(idSite, genreSite) {
		const url = `${__configuration.apiUrl}/massia/compte-ouverts/header/${idSite}/${genreSite}/`;
		const res = await this.$http.get(url);
		return res.data;
	}
}

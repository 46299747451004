export default class StocksConfigurationProduitsNiveauxController {
	static $inject = [
		'$scope',
		'$state',
		'$uibModal',
		'notification',
		'$translate',
		'$filter',
		'moment',
		'StocksConfigurationProduitsNiveauxService',
		'MassiaRightsService',
		'localStorageService'
	];

	constructor(
		$scope,
		$state,
		$uibModal,
		notification,
		$translate,
		$filter,
		moment,
		StocksConfigurationProduitsNiveauxService,
		MassiaRightsService,
		localStorageService
	) {
		this.$scope = $scope;
		this.$state = $state;
		this.$uibModal = $uibModal;
		this.notification = notification;
		this.$translate = $translate;
		this.$filter = $filter;
		this.moment = moment;
		this.StocksConfigurationProduitsNiveauxService = StocksConfigurationProduitsNiveauxService;
		this.MassiaRightsService = MassiaRightsService;
		this.localStorageService = localStorageService;
		this.loading = false;
		this.idSite = null;
		this.idNiveau = null;
		this.sites = [];
		this.produits = [];
		this.lastCodeNiveau2 = null;
	}

	async $onInit() {
		await this.loadNiveauxHierarchique();

		if (this.niveauxHierarchique.length > 0) {
			this.niveauxForSelect = [
				...this.niveauxHierarchique.flatMap((x) =>
					x.items.map((y) => {
						return { id: y.id, label: x.label + ' > ' + y.label };
					})
				)
			];
			this.idNiveau = this.niveauxForSelect[0].id;
		}

		await this.loadSites();
		const stockSiteId = this.localStorageService.get('STOCK_CONFIG_PROD_NH_STOCK_SITE_ID');
		if (this.sites.length > 0) {
			this.idSite = stockSiteId || this.sites[0].id;
			if (this.idSite) {
				await this.loadProduits();
			}
		}

		//watcher idSite
		this.$scope.$watch(
			() => this.idSite,
			(newValue, oldValue) => {
				if (newValue !== oldValue) {
					this.localStorageService.set('STOCK_CONFIG_PROD_NH_STOCK_SITE_ID', newValue);
					this.produits = [];
				}
			}
		);
		this.$scope.$watch(
			() => this.idNiveau,
			(newValue, oldValue) => {
				if (newValue !== oldValue) {
					this.produits = [];
				}
			}
		);
	}

	async loadSites() {
		this.startLoading();
		try {
			this.sites = await this.StocksConfigurationProduitsNiveauxService.getSitesCommerciauxSansSociete();
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async loadNiveauxHierarchique() {
		this.startLoading();
		try {
			this.niveauxHierarchique = await this.StocksConfigurationProduitsNiveauxService.getProduitsStocksElements();
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async loadProduits() {
		this.startLoading();

		try {
			const niveau = `${this.idNiveau.niveauHierarchiqueId};${this.idNiveau.elementId}`;
			const produitList = await this.StocksConfigurationProduitsNiveauxService.getProduitsBySiteAndNiveau2(this.idSite, niveau);

			this.niveaux3List = [...new Set(produitList.map((x) => x.codeNiveau3.toLowerCase()))];

			const updatedProduits = [];

			produitList.forEach((x) => {
				//on ajoute un produit vide pour chaque produit de niveau 1
				if (x.orderNiveau === 1) {
					const newProduit = {
						code: '',
						codeNiveau1: x.codeNiveau1,
						codeNiveau2: x.codeNiveau2,
						codeNiveau3: x.codeNiveau3,
						id: 0,
						isVisible: false,
						libelle: '',
						niveauFlatLabel: x.niveauFlatLabel,
						orderNiveau: 0,
						producteurId: 0,
						producteurLibelle: ''
					};
					updatedProduits.push(newProduit);
				}

				updatedProduits.push(x);
			});

			this.produits = updatedProduits;
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	getProduitsByNiveau3(niveaux3) {
		return this.produits.filter((produit) => {
			return produit.codeNiveau3.toLowerCase() === niveaux3.toLowerCase();
		});
	}

	//pour interdire de sortir du niveau
	dragoverCallback(index) {
		return index !== 0;
	}

	handleDrop(targetIndexInNiveau3, selectedItem) {
		const selectedIndex = this.produits.findIndex((x) => x.code === selectedItem.code);

		let targetItem = this.produits.filter((x) => x.codeNiveau3 === selectedItem.codeNiveau3)[targetIndexInNiveau3];

		//gestion du cas ou on drop sur le dernier element
		let isDropInLast = false;
		if (typeof targetItem === 'undefined') {
			isDropInLast = true;
			const listNiveau3 = this.produits.filter((x) => x.codeNiveau3 === selectedItem.codeNiveau3);
			targetItem = listNiveau3[targetIndexInNiveau3 - 1];
		}

		const targetItemIndex = this.produits.findIndex((x) => x.code === targetItem.code);

		const action = targetItemIndex > selectedIndex ? 'up' : 'down';

		this.produits.splice(selectedIndex, 1);
		if (action === 'up') {
			this.produits.splice(isDropInLast ? targetItemIndex : targetItemIndex - 1, 0, selectedItem);
		} else if (action === 'down') {
			this.produits.splice(targetItemIndex, 0, selectedItem);
		}

		//reorder produits by orderNiveau after drag and drop with init when codeNiveau3 is different
		this.reOrderProduits();
	}

	reOrderProduits() {
		let lastCodeNiveau3 = null;
		let count = 0;
		this.produits.forEach((produit) => {
			if (lastCodeNiveau3 !== produit.codeNiveau3) {
				count = 0;
				lastCodeNiveau3 = produit.codeNiveau3;
				produit.orderNiveau = count;
			} else {
				produit.orderNiveau = count;
			}
			count++;
		});
	}

	handleSelectedProduit(produit) {
		produit.selected = true;
		return produit;
	}

	async sauvegarder() {
		this.startLoading();
		try {
			const produitsChangedToUpdate = this.produits.filter((produit) => produit.orderNiveau !== 0);
			if (produitsChangedToUpdate.length === 0) {
				return false;
			}
			await this.StocksConfigurationProduitsNiveauxService.updateProduitsStockNiveau2(this.idSite, produitsChangedToUpdate);
			this.notification.success('STOCKS_CONFIGURATION_PRODUITS_NIVEAUX.SUCCESS_UPDATE');
		} catch (ex) {
			this.notification.error(ex.data);
			return false;
		} finally {
			this.stopLoading();
		}
	}

	isChangedProductList() {
		return this.produits.some((produit) => produit.isChanged);
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	debug() {
		console.log('🔥: this.produits', this.produits);
	}
}

export default class CommandePopupController {
	static $inject = [
		'$scope',
		'$stateParams',
		'CommandesService',
		'notification',
		'ModalService',
		'$uibModal',
		'moment',
		'$translate',
		'globalizationManagementService'
	];

	constructor($scope, $stateParams, CommandesService, notification, ModalService, $uibModal, moment, $translate, globalizationManagementService) {
		this.$scope = $scope;
		this.$stateParams = $stateParams;
		this.CommandesService = CommandesService;
		this.notification = notification;
		this.ModalService = ModalService;
		this.$uibModal = $uibModal;
		this.moment = moment;
		this.$translate = $translate;

		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
	}

	async $onInit() {
		console.log(this.paiement);
		this.idModePaiement = this.paiement;
		await this.getModePaiements();
	}

	annuler() {
		this.modalInstance.close();
	}

	async confirmer() {
		try {
			if (this.dateFacture && this.idModePaiement) {
				const data = await this.CommandesService.transformFacture(this.idCommande, this.dateFacture, this.idModePaiement);
				if (data) {
					this.modalInstance.close(data);
				}
			}
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async getModePaiements() {
		if (!this.paiements) {
			try {
				this.paiements = await this.CommandesService.getModesPaiement();
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			}
		}
	}
}

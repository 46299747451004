import RequestRoute from './_config/portail.request.routes';
import requestList from './components/request-list';
import addUserRequestList from './components/add-user-request-list';
import navRequest from './components/nav-request';

const moduleName = 'app.massia.common.portail.request';

angular
	.module(moduleName, [])
	.config(RequestRoute)
	.component('portailNavRequest', navRequest)
	.component('portailRequestList', requestList)
	.component('portailAddUserRequestList', addUserRequestList);
// angular.module(moduleName, []).config(RequestRoute).component('addUserRequestList', addUserRequestList);

export default moduleName;

import DocumentModal from './document.modal.model';

export default class documentModalFormController {
	constructor($scope, $stateParams, $timeout, TemplateImpressionService, MassiaApplicationService, DocumentsTypesService, DocumentsService) {
		this.$scope = $scope;
		this.$stateParams = $stateParams;
		this.loading = false;
		this.type = 'info';
		this.timeout = $timeout;
		this.templateImpressionService = TemplateImpressionService;
		this.value = 0;
		this.cancelButton = 'IMPRESSION.CANCEL';
		this.codeExist = false;
		this.massiaAppService = MassiaApplicationService;
		this.DocumentsTypesService = DocumentsTypesService;
		this.DocumentsService = DocumentsService;
	}

	async $onInit() {
		this.$scope.$broadcast('DocumentModalValidator');

		this.form = {
			data: this.resolve && this.resolve.data && this.resolve.data.id ? new DocumentModal(this.resolve.data) : new DocumentModal(),
			file: {}
		};
		console.log(this.resolve, this.form.data);

		try {
			//this.form.data.estCodeAuto = await this.DocumentsService.estCodeAuto();
			this.form.data.estCodeAuto = false;
		} catch (ex) {
			//do nothin, pas auto par défaut
		}

		this.checkCode();
		this.form.data.isValid();

		this.loadTypes().then((data) => {
			this.types = data;
			const application = this.massiaAppService.getApplication();
			if (application === 'performance') {
				const type = this.types.find(function (e) {
					return e.code === 'S_PERFORMANCE';
				});
				this.form.data.idType = type.id;
				this.form.data.libelleType = type.libelle;
			} else {
				const typeIndex = this.types.findIndex(function (e) {
					return e.code === 'S_PERFORMANCE';
				});
				if (typeIndex >= 0) {
					this.types.splice(typeIndex, 1);
				}
			}

			if (this.typeSelected) {
				const type = this.types.find((e) => e.code == this.typeSelected);
				this.form.data.idType = type.id;
				this.form.data.libelleType = type.libelle;
				this.isDisabledType = true;
			} else {
				this.isDisabledType = false;
			}
		});
	}

	async loadTypes() {
		try {
			const types = await this.DocumentsTypesService.getAll();
			return types;
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	removeUnnecessaryDomain(domains) {
		for (let i = 0; i < domains.length; i++) {
			if (!domains[i].droits || domains[i].droits.print == undefined) {
				//if(domains[i].niveauMax < 31){//SP : NON, prendre si droit sur impression
				domains.splice(i, 1);
				i--;
			}
		}
	}

	async ok() {
		this.loading = true;
		this.fileInfoOpen = false;
		if (this.form.data.isValid()) {
			this.value = 50;
			this.progress = 'Envoi en cours';
			try {
				const that = this;
				await this.DocumentsService.onFileSelect([this.form.file], 0, this.form.data.code, this.form.data.idType, function (data) {
					that.form.data.id = data;
					that.form.data.sizeCoverted = that.form.data.size / 100 + 'ko';
					that.value = 100;
					that.type = 'success';
					that.progress = 'Upload réussi';
					that.fileInfoOpen = true;

					that.modalInstance.close(that.form.data);
				});
			} catch (err) {
				this.value = 100;
				this.type = 'danger';
				this.progress = err.data;
			}
			this.cancelButton = 'Fermer';
		}
	}

	cancel() {
		this.$scope.$parent.$dismiss();
	}

	change(event) {
		const fileInfo = event.target.files[0];
		this.form.file = fileInfo;
		const file = this.getFileAndExtension(fileInfo.name);
		this.form.data.libelle = file[0];
		this.form.data.extension = file[1];
		this.form.data.size = fileInfo.size;
		this.form.data.sizeCoverted = fileInfo.size / 100 + 'ko';
		this.form.data.dateCreation = fileInfo.lastModified;
		this.fileInfoOpen = true;
		this.loading = false;
	}

	getFileAndExtension(file) {
		const result = file.split('.');
		return result;
	}

	openFileUploader() {
		document.getElementById('file-upload').click();
	}

	async checkCode() {
		if (!this.form.data.estCodeAuto) {
			if (!this.form.data.code) {
				this.form.data.code = Date.now().toString().substring(4);
			}
			this.codeExist = await this.DocumentsService.codeExists(this.form.data.code);
		} else {
			this.codeExist = false;
		}
	}

	checkform(form) {
		console.log(form);
	}
}

documentModalFormController.$inject = [
	'$scope',
	'$stateParams',
	'$timeout',
	'TemplateImpressionService',
	'MassiaApplicationService',
	'DocumentsTypesService',
	'DocumentsService'
];

const serviceUrl = `${__configuration.apiUrl}/massia/factures`;

export default class FacturesService {
	static $inject = ['$http', 'notification', '$translate', 'localStorageService', 'AuthConstant'];

	constructor($http, notification, $translate, localStorageService, AuthConstant) {
		this.$http = $http;
		this.notification = notification;
		this.$translate = $translate;
		this.localStorageService = localStorageService;
		this.AuthConstant = AuthConstant;
	}

	async getFactures(filters, sorts, pagination, idSocieteCom, idSiteCom, dateDebut, dateFin) {
		const res = await this.$http.get(serviceUrl, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {}),
				idSocieteCom: JSON.stringify(idSocieteCom || 0),
				idSiteCom: JSON.stringify(idSiteCom || 0),
				dateDebut: JSON.stringify(dateDebut || null),
				dateFin: JSON.stringify(dateFin || null)
			}
		});

		return res.data;
	}

	async getFacturesFromFacturation(pListeFactureIds) {
		const res = await this.$http.get(serviceUrl, {
			params: {
				listFactureIds: JSON.stringify(pListeFactureIds || [])
			}
		});

		return res.data;
	}

	async deleteFactureById(id) {
		const url = `${serviceUrl}/${id}`;
		return this.$http.delete(url);
	}

	async declotureFactureById(id) {
		const url = `${serviceUrl}/decloture/${id}`;
		return this.$http.put(url);
	}

	async avoirRefactAutomatique(data) {
		const url = `${serviceUrl}/avoirRefact`;
		const result = await this.$http.post(url, data);
		return result.data;
	}

	async getFactureById(id) {
		const url = `${serviceUrl}/${id}`;
		const res = await this.$http.get(url);
		return res.data;
	}
	async getModesPaiement() {
		const serviceUrl = `${__configuration.apiUrl}/massia/paiements/headers`;
		const res = await this.$http.get(serviceUrl);
		return res.data;
	}

	async getTypeTicket() {
		const url = 'assets/enum/typePrix.json';
		const res = await this.$http.get(url);
		return res.data;
	}

	async getSitesProducteurs() {
		const siteUrl = `${__configuration.apiUrl}/massia/sites-producteurs`;
		const res = await this.$http.get(siteUrl);

		return res.data.items;
	}
	async getProduitsSelectionnables(filtres) {
		const monFiltre = JSON.stringify(filtres);
		const url = `${__configuration.apiUrl}/massia/factures/produitsFactureManu?filters=${monFiltre}`;
		const res = await this.$http.get(url);
		return res.data;
	}
	async getPrestationsSelectionnables(filtres) {
		const monFiltre = JSON.stringify(filtres);
		const url = `${__configuration.apiUrl}/massia/factures/prestationsFactureManu?filters=${monFiltre}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getTransportsSelectionnables(filtres) {
		const monFiltre = JSON.stringify(filtres);
		const url = `${__configuration.apiUrl}/massia/factures/transportsFactureManu?filters=${monFiltre}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getDroits() {
		const url = `${__configuration.apiUrl}/massia/droit-facture`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getEtats() {
		const url = 'assets/enum/factures/etat.json';
		const res = await this.$http.get(url);
		return res.data;
	}

	async getTypes() {
		const url = 'assets/enum/factures/typeFacture.json';
		const res = await this.$http.get(url);
		return res.data;
	}

	async getTypesSurAvoirAuto() {
		const url = 'assets/enum/factures/typeFacture.create.avoirAuto.json';
		const res = await this.$http.get(url);
		return res.data;
	}

	async getTypesSurCreation(droit) {
		let url = 'assets/enum/factures/typeFacture.create.json';
		if (droit == 'avoir') {
			url = 'assets/enum/factures/typeFacture.create.avoirManuel.json';
		}
		if (droit == 'facture') {
			url = 'assets/enum/factures/typeFacture.create.factureManuelle.json';
		}
		const res = await this.$http.get(url);
		return res.data;
	}

	async getSocietes() {
		const url = `${__configuration.apiUrl}/massia/societes-commerciales/header`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getPresentation() {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/affichages-factures`);
		return res.data;
	}

	async getFactureInvalides(pListeFactureIds) {
		const url = `${__configuration.apiUrl}/massia/affichages-factures/invalide`;
		const res = await this.$http.post(url, pListeFactureIds);

		return res.data;
	}

	async print(ids, fileName, printOption) {
		const url = `${__configuration.apiUrl}/massia/affichages-factures/print`;
		const res = await this.$http.post(
			url,
			{
				ids: ids,
				fileName: fileName,
				isRectoVerso: printOption.isRectoVerso,
				isFacture: printOption.isFacture,
				isTraite: printOption.isTraite,
				isReleve: printOption.isReleve,
				isTraiteFusion: printOption.isTraiteFusion,
				isDuplicata: printOption.isDuplicata,
				haveSeparateFiles: printOption.haveSeparateFiles,
				showFactureSoldee: printOption.showFactureSoldee,
				sendToPortail: printOption.sendToPortail,
				sendBlToPortail: printOption.sendBlToPortail
			},
			{
				responseType: 'arraybuffer'
			}
		);

		return res;
	}
	async printAll(ids, fileName, printOption, allOptions) {
		const filters = JSON.stringify(allOptions.filters || []);
		const idSocieteCom = JSON.stringify(allOptions.societeComIdSelected || 0);
		const idSiteCom = JSON.stringify(allOptions.siteComIdSelected || 0);
		const dateDebut = JSON.stringify(allOptions.dateDebut || null);
		const dateFin = JSON.stringify(allOptions.dateFin || null);
		const url = `${__configuration.apiUrl}/massia/affichages-factures/print-with-search?dateDebut=${dateDebut}&dateFin=${dateFin}&idSocieteCom=${idSocieteCom}&idSiteCom=${idSiteCom}&filters=${filters}`;
		const res = await this.$http.post(
			url,
			{
				ids: ids,
				fileName: fileName,
				isRectoVerso: printOption.isRectoVerso,
				isFacture: printOption.isFacture,
				isTraite: printOption.isTraite,
				isReleve: printOption.isReleve,
				isTraiteFusion: printOption.isTraiteFusion,
				isDuplicata: printOption.isDuplicata,
				haveSeparateFiles: printOption.haveSeparateFiles,
				showFactureSoldee: printOption.showFactureSoldee,
				sendToPortail: printOption.sendToPortail,
				sendBlToPortail: printOption.sendBlToPortail
			},
			{
				responseType: 'arraybuffer'
			}
		);

		return res;
	}

	async getMessageErreur() {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/affichages-factures/message_erreur`);
		return res.data;
	}

	async cancelPrint() {
		const url = `${__configuration.apiUrl}/massia/affichages-factures/cancel-print`;
		const res = await this.$http.get(url);

		return res;
	}

	async deleteFacturesChecked(listFacturesId) {
		const deleteUrl = `${__configuration.apiUrl}/massia/factures/suppression`;
		return this.$http.delete(deleteUrl, {
			params: {
				listFactureIds: JSON.stringify(listFacturesId || [])
			}
		});
	}

	async sendFacturesByMail(factureIds) {
		const closeUrl = `${__configuration.apiUrl}/massia/factures/sendByMail`;
		await this.$http.put(closeUrl, factureIds);
	}

	async closeFacturesChecked(listFactureIds, withCloture, idSocieteCom, idSiteCom, dateDebut, dateFin) {
		const data = {
			listFactureIds: listFactureIds,
			withCloture: withCloture,
			idSocieteCom: idSocieteCom,
			idSiteCom: idSiteCom,
			dateDebut: dateDebut,
			dateFin: dateFin
		};
		const closeUrl = `${__configuration.apiUrl}/massia/factures/checkToCloture`;
		const res = await this.$http.put(closeUrl, data);

		if (res && res.data && res.data.isPontOk && res.data.facturesToTreat && res.data.facturesToTreat.length > 0) {
			let waitingDatas = this.localStorageService.get(this.AuthConstant.MASSIA_WAITING_DATA_STORAGE);
			if (!waitingDatas) {
				waitingDatas = {};
			}
			if (!waitingDatas.facture) {
				waitingDatas.facture = {
					url: `${__configuration.apiUrl}/massia/factures/checkClotureGeneration`,
					success: 'FACTURES.CLOSECHECKED.DOCS_SUCCESS',
					error: 'FACTURES.CLOSECHECKED.DOCS_ECHEC',
					items: []
				};
			}

			for (let i = 0; i < res.data.facturesToTreat.length; i++) {
				waitingDatas.facture.items.push({ ticks: res.data.facturesToTreat[i].processTicks, target: res.data.facturesToTreat[i] });
			}

			this.localStorageService.set(this.AuthConstant.MASSIA_WAITING_DATA_STORAGE, waitingDatas);
		}

		return res.data;
	}

	async genereJournal(listFactureIds) {
		const url = `${__configuration.apiUrl}/massia/facture-pont-comptable/journal-vente`;
		const res = await this.$http.post(
			url,
			{
				listFactureIds: listFactureIds
			},
			{
				responseType: 'arraybuffer'
			}
		);

		return res;
	}

	async generePontComptable(listFactureIds, isSimulation) {
		const data = {
			listFactureIds: listFactureIds,
			isSimulation: isSimulation
		};
		const url = `${serviceUrl}/generePontComptable`;
		const result = await this.$http.post(url, data);
		return result;
	}

	async genererTraites(filters, sorts, idSocieteCom, idSiteCom, dateDebut, dateFin) {
		const url = `${__configuration.apiUrl}/massia/traite-bancaire`;
		const res = await this.$http.get(url, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				idSocieteCom: JSON.stringify(idSocieteCom || 0),
				idSiteCom: JSON.stringify(idSiteCom || 0),
				dateDebut: JSON.stringify(dateDebut || null),
				dateFin: JSON.stringify(dateFin || null)
			}
		});

		return res;
	}

	async getInfoResultat(listFactureIds, isSimulation) {
		const data = {
			listFactureIds: listFactureIds,
			isSimulation: isSimulation
		};
		const url = `${serviceUrl}/infoResultatPontComptable`;
		const result = await this.$http.post(url, data);
		return result.data;
	}

	async updateFacture(data) {
		const url = `${serviceUrl}/${data.idFacture}`;
		return this.$http.put(url, data);
	}

	async createFacture(data) {
		const result = await this.$http.post(serviceUrl, data);
		return result.data;
	}

	// SL OK
	async createArticle(data) {
		const url = `${__configuration.apiUrl}/massia/articles`;
		return this.$http.post(url, data);
	}

	async updateArticle(data) {
		const url = `${__configuration.apiUrl}/massia/articles/${data.idFactSomeThing}`;
		return this.$http.put(url, data);
	}

	// SL OK
	async deleteArticle(idFactSomeThing, type) {
		let url = null;
		if (type == 0) {
			url = `${__configuration.apiUrl}/massia/articles/produit/${idFactSomeThing}`;
		} else {
			url = `${__configuration.apiUrl}/massia/articles/prestation/${idFactSomeThing}`;
		}
		return this.$http.delete(url);
	}

	async reverseQuantite(idFactSomeThing, data) {
		const url = `${__configuration.apiUrl}/massia/articles/reverse-quantite/${idFactSomeThing}`;
		return this.$http.put(url, data);
	}

	// SL OK
	async getNomSitesCommerciaux(val, idSociete) {
		const nomSocieteUrl = `${__configuration.apiUrl}/massia/sites-commerciaux/typeAhead/${idSociete}/${val}`;
		const res = await this.$http.get(nomSocieteUrl);
		return res.data;
	}

	// SL OK
	async getNomSitesClients(val, idSociete) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-clients/typeAheadCompteOuvert/${idSociete}/${val}`);
		return res.data;
	}

	async getNomClientDivers(val, idSociete) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/clients-divers/typeAhead/${idSociete}/${val}`);
		return res.data;
	}

	// SL OK
	async getNomChantiersByClient(valeur, idClient) {
		const url = `${__configuration.apiUrl}/massia/chantiers/headersByClient/${idClient}/${valeur}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	// SL OK
	async GetDateSimulated(dateGeneration, paiement) {
		const url = `${__configuration.apiUrl}/massia/paiements/simulation`;
		const res = await this.$http.get(url, {
			params: {
				dateGeneration: JSON.stringify(dateGeneration || []),
				paiement: JSON.stringify(paiement || [])
			}
		});
		return res.data;
	}

	// SL OK
	async getUniteArticle(idArticle, typeArticle) {
		const url = `${__configuration.apiUrl}/massia/unites/uniteArticle`;
		const res = await this.$http.get(url, {
			params: {
				idArticle: JSON.stringify(idArticle || null),
				typeArticle: JSON.stringify(typeArticle || null)
			}
		});
		return res.data;
	}

	// SL OK
	async getPSArticle(idArticle, typeArticle, idSociete, idSiteCommercial, idClient, idChantier, idProducteur, idTypeBenne = null, idZone = null) {
		const url = `${__configuration.apiUrl}/massia/grillesTarifaires/pixArticle`;
		const res = await this.$http.get(url, {
			params: {
				idArticle: JSON.stringify(idArticle || null),
				typeArticle: JSON.stringify(typeArticle || null),
				idSociete: JSON.stringify(idSociete || null),
				idSiteCommercial: JSON.stringify(idSiteCommercial || null),
				idClient: JSON.stringify(idClient || null),
				idChantier: JSON.stringify(idChantier || null),
				idProducteur: JSON.stringify(idProducteur || null),
				idTypeBenne: JSON.stringify(idTypeBenne || null),
				idZone: JSON.stringify(idZone || null)
			}
		});
		return res.data;
	}

	// SL OK
	async getNomLieux(valeur, idClient) {
		const url = `${__configuration.apiUrl}/massia/lieux/typeAhead/${valeur}/${idClient}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getTVAArticle(code, filtres) {
		const monFiltre = JSON.stringify(filtres);
		const url = `${__configuration.apiUrl}/massia/parametres/${code}/valeurs?filters=${monFiltre}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getPiecesFacturationLiees(idSociete, idClient, idTypeFacture) {
		const url = `${serviceUrl}/piecesLiees`;
		const res = await this.$http.get(url, {
			params: {
				idSociete: JSON.stringify(idSociete),
				idClient: JSON.stringify(idClient),
				idTypeFacture: JSON.stringify(idTypeFacture)
			}
		});
		return res.data;
	}

	async getSocietesCom() {
		const url = `${__configuration.apiUrl}/massia/societes-commerciales`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getSitesCom(pIdSociete) {
		const url = `${__configuration.apiUrl}/massia/sites-commerciaux/header/${pIdSociete}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getClients(pIdSociete, idTypes) {
		const url = `${__configuration.apiUrl}/massia/sites-clients/type/header/${pIdSociete}`;
		const res = await this.$http.get(url, {
			params: {
				idTypes: JSON.stringify(idTypes || [])
			}
		});
		return res.data;
	}

	async getTypesClient(domaine) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/types/${domaine}/headers`);
		return res.data;
	}

	async getChantiers(pIdClient) {
		const dateNull = null;
		const url = `${__configuration.apiUrl}/massia/chantiers/header/${pIdClient}/${dateNull}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async exportListeFactures(modeImpr, idSocieteCom, idSiteCom, dateDebut, dateFin, filters, sorts) {
		const url = `${serviceUrl}/print`;
		const res = await this.$http.get(url, {
			params: {
				idSocieteCom: JSON.stringify(idSocieteCom || 0),
				idSiteCom: JSON.stringify(idSiteCom || 0),
				dateDebut: JSON.stringify(dateDebut || null),
				dateFin: JSON.stringify(dateFin || null),
				modeImpr: JSON.stringify(modeImpr),
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || [])
			},
			responseType: 'arraybuffer'
		});
		return res;
	}

	async getFacturesForPont(filters, sorts, pagination, searchingFormFactures) {
		const url = `${serviceUrl}/pont`;
		const res = await this.$http.get(url, {
			params: {
				searchingFormFactures: JSON.stringify(searchingFormFactures || {}),
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {})
			}
		});

		return res.data;
	}

	async printFacturesForPont(filters, sorts, searchingFormFactures) {
		const url = `${serviceUrl}/print-pont`;
		const res = await this.$http.get(url, {
			params: {
				searchingFormFactures: JSON.stringify(searchingFormFactures || {}),
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || [])
			},
			responseType: 'arraybuffer'
		});
		return res;
	}

	async updateLibelleProduit(id, libelle, typeArticle) {
		const data = {
			id: id,
			libelle: libelle,
			typeArticle: typeArticle
		};
		const url = `${serviceUrl}/libelleProduit`;
		return this.$http.put(url, data);
	}

	async updateFactureCommentaire(id, libelle) {
		const data = {
			id: id,
			libelle: libelle
		};
		const url = `${__configuration.apiUrl}/massia/facture-commentaire`;
		return this.$http.put(url, data);
	}

	async deleteFactureCommentaire(id) {
		const url = `${__configuration.apiUrl}/massia/facture-commentaire/${id}`;
		return this.$http.delete(url);
	}

	async createFactureCommentaire(article, isBefore) {
		const data = {
			isBefore: isBefore,
			idFacture: article.idFacture,
			idFactureProduit: article.typeArticle == 0 ? article.idFactSomeThing : null,
			idFacturePrestation: article.typeArticle == 1 || article.typeArticle == 2 ? article.idFactSomeThing : null,
			idFactureCommentaire: article.typeArticle == 5 ? article.idFactSomeThing : null
		};
		const url = `${__configuration.apiUrl}/massia/facture-commentaire`;
		const result = await this.$http.post(url, data);
		return result.data;
	}

	async getFiltreClients(idSociete) {
		const url = `${__configuration.apiUrl}/massia/sites-clients/header/${idSociete}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async duplicateFacture(facture, idClients) {
		const data = {
			facture: facture,
			articleBlocks: facture.listeArticlesByBlock,
			idClients: idClients
		};
		const url = `${serviceUrl}/duplicate`;
		const result = await this.$http.post(url, data);
		return result.data;
	}

	async regenererFacture(idFacture, comptoirWithFiles = false) {
		const url = `${__configuration.apiUrl}/massia/pre-facturation/regenerer/` + idFacture;
		const result = await this.$http.post(url, comptoirWithFiles);
		return result.data;
	}

	async getCommentaireClient(id) {
		const url = `${serviceUrl}/commentaire-client/${id}`;
		const res = await this.$http.get(url);
		return res.data;
	}
	async passerEnComptabilise(listFactureIds) {
		try {
			const data = {
				listFactureIds: listFactureIds
			};
			const url = `${serviceUrl}/passer-en-comptabilise`;
			const result = await this.$http.post(url, data);
			return result;
		} catch (err) {
			console.log(err);
			throw err;
		}
	}

	async getFactureComposant(id, domaine) {
		const url = `${__configuration.apiUrl}/massia/facture-composant/${id}/${domaine}`;
		const res = await this.$http.get(url);
		return res.data;
	}
}

import DisplaySetting from '../models/DisplaySetting';

export default class SettingPortailService {
	constructor($http, Upload) {
		this.$http = $http;
		this.Upload = Upload;
		this.url = `${__configuration.apiUrl}/massia/portail/setting`;
		this.urlImages = `${__configuration.apiUrl}/portail/settings`;
		this.docUrl = `${this.url}/document`;
		this.displayUrl = `${this.url}/display`;
		this.config = {};
		this.docSetting = [];
		this.activesDomains = [];
		this.displaySetting = new DisplaySetting();
		this.themes = [];
	}

	getSetting() {
		return this.$http.get(this.url);
	}

	saveSetting(data) {
		return this.$http.post(this.url, data);
	}

	async getDocSettings() {
		this.docSetting = (await this.$http.get(this.docUrl)).data || [];
	}

	saveDocSetting(liste) {
		return this.$http.post(this.docUrl, liste);
	}
	updateDocSetting(liste) {
		return this.$http.put(this.docUrl, liste);
	}
	getDocSetting(code) {
		return this.$http.get(`${this.docUrl}/${code}`);
	}

	getAutoFilename(idEntity, domain, format, data = null) {
		const info = {
			domain: domain,
			format: format,
			laboratoire: data?.laboratoire,
			produit: data?.produit,
			producteur: data?.producteur
		};
		return this.$http.post(`${__configuration.apiUrl}/massia/portail/file/filename/${idEntity}`, info);
	}

	async getImagesPortail() {
		this.images = (await this.$http.get(`${this.displayUrl}/images`)).data || [];
	}
	async getThemes() {
		this.themes = (await this.$http.get(`${this.displayUrl}/themes`)).data || [];
		return this.themes;
	}
	async getDisplaySetting() {
		const data = (await this.$http.get(this.displayUrl)).data;
		this.displaySetting = new DisplaySetting(data);
	}
	createDisplaySetting(data) {
		return this.$http.post(this.displayUrl, data);
	}
	updateDisplaySetting(data) {
		return this.$http.put(`${this.displayUrl}/${data.id}`, data);
	}
	deleteDisplaySetting(data) {
		return this.$http.delete(`${this.displayUrl}/${data.id}`);
	}

	async uploadImage($file, type) {
		const apiUrl = `${this.displayUrl}/upload-image/${type}`;

		return this.Upload.upload({
			url: apiUrl,
			method: 'POST',
			file: $file[0]
		});
	}
	setImageUrl(filename) {
		const array = filename.split('.');
		return `${this.urlImages}/${array[0]}/${array[1]}`;
	}
}

SettingPortailService.$inject = ['$http', 'Upload'];

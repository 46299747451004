import validatorBuilder from 'validator-builder';

let offreEnteteValidator = null;

export default class OffreEntete {
	constructor(data) {
		data = data || {};
		this.id = data.id;
		this.code = data.code;
		this.etat = data.etat;
		this.idType = data.idType;
		this.idPersonneDemandeValidation = data.idPersonneDemandeValidation;
		this.idPersonneSuivi = data.idPersonneSuivi;
		this.idPersonneConfirmation = data.idPersonneConfirmation;
		this.commentaire = data.commentaire;
		this.dateCreation = data.dateCreation;
		this.dateValidite = data.dateValidite;
		this.dateDemande = data.dateDemande || null;
		this.propositions = data.propositions || [];
		this.estCodeAuto = data.estCodeAuto;
		this.isDevis = data.isDevis;
		this.isPropositionValide = data.isPropositionValide;
		this.isSuivi = data.isSuivi || false;
		this.resultatValidation = data.resultatValidation;
		this.etudeAffaire = data.etudeAffaire || enumEtudeAffaire.VIDE;
		this.fileName = data.fileName;
		this.validateur = data.validateur;

		this.propositions = this.propositions.map((x) => {
			x.proposition = this.propositions.find((p) => p.id === x.idPropositionOrigine);
			return x;
		});
	}

	isValid() {
		offreEnteteValidator = offreEnteteValidator || validatorBuilder.getInstanceFor('OffreEntete');
		const validationResults = offreEnteteValidator.validate(this);
		return validationResults.isValid;
	}
}

export const enumEtudeAffaire = {
	VIDE: 0,
	ETUDE: 1,
	AFFAIRE: 2
};

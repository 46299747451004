export default {
	MASSIA_AUTH_DATA_STORAGE: 'MassiaAuthorizationData',
	MASSIA_USER_DATA_STORAGE: 'MassiaUserData',
	MASSIA_PERSISTS_USER_DATA_STORAGE: 'MassiaPersistsUserData',
	MASSIA_WAITING_DATA_STORAGE: 'MassiaWaitingData',
	MASSIA_CLIENT_NAME: 'Client',
	MASSIA_CULTURE: 'culture',
	MASSIA_APPLICATION: 'application',
	MASSIA_VERSION: 'version'
};

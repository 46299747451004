export default class AddUserRequestListController {
	constructor($scope, $state, notification, UserRequestService, DomainPortailService) {
		this.$scope = $scope;
		this.$state = $state;
		this.notification = notification;
		this.UserRequestService = UserRequestService;
		this.DomainPortailService = DomainPortailService;
	}
	selectedReq = null;
	domaines = [];

	async $onInit() {
		this.loadAll();
	}

	async loadAll() {
		try {
			this.addRequest = (await this.UserRequestService.getAllAddUserRequest()).data || [];
			this.domaines = (await this.DomainPortailService.getAll()).data || [];
			console.log(this.addRequest);
		} catch (error) {
			this.notification.error(error.data.message);
		}
	}

	async closeReq(request, validate) {
		request.loading = true;
		request.status = validate ? 1 : 2;
		try {
			const res = await this.UserRequestService.updateAddUserRequest(request);
			if (validate) {
				this.notification.success('PORTAIL.USER.UPDATE_REQUEST_VALID');
			} else {
				this.notification.success('PORTAIL.USER.UPDATE_REQUEST_REFUS');
			}

			if (res.data == 'Email Error') {
				this.notification.warning('PORTAIL.USER.UPDATE_REQUEST_MAIL_ERROR');
			} else {
				this.notification.success('PORTAIL.USER.UPDATE_REQUEST_MAIL');
			}
			this.UserRequestService.loadAll();
			this.loadAll();
			const idx = this.addRequest.findIndex((x) => x.id === request.id);
			this.addRequest.splice(idx, 1);
		} catch (error) {
			this.notification.error(error.data.message);
		}
	}

	selectReq(req) {
		this.selectedReq = req;
		this.selectedReq.rights = this.mapDomaines(this.selectedReq.rights);
		console.log(this.selectedReq);
	}

	mapDomaines(listAccess) {
		this.domaines.items.forEach((domaine) => {
			const found = listAccess.some((access) => access.domainId === domaine.id);

			if (!found) {
				listAccess.push({
					domainId: domaine.id,
					domainNom: domaine.libelle,
					domainCode: domaine.code,
					accessLevel: 0,
					requestId: null
				});
			}
		});
		return listAccess;
	}
}

AddUserRequestListController.$inject = ['$scope', '$state', 'notification', 'UserRequestService', 'DomainPortailService'];

/* @ngInject */
export default class VersionService {
	/**
	 *
	 * @param {import('./api.service').default} apiSvc
	 */
	constructor(ApiService) {
		this.apiSvc = ApiService;
	}

	getVersion() {
		return this.apiSvc.get('massia/environment/version');
	}
}

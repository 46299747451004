export default function Configure($stateProvider) {
	$stateProvider.state('stocks-configuration-produits-niveaux', {
		parent: 'gestion',
		url: '/stocks-configuration-produits-niveaux',
		views: {
			gestion: {
				template: '<ui-view></ui-view>'
			}
		},
		ncyBreadcrumb: {
			label: '{{ "LAYOUT.NAVIGATION.STOCKS" | translate }}'
		}
	});

	$stateProvider.state('stocks-configuration-produits-niveaux.list', {
		url: '/list',
		template: '<stocks-configuration-produits-niveaux></stocks-configuration-produits-niveaux>',
		rights: { domain: 'stockconfigpnh', right: 'read' },
		ncyBreadcrumb: {
			label: '{{ "STOCKS.BREADCRUMBS.STOCKS_CONFIGURATION_PRODUITS_NIVEAUX" | translate}}'
		},
		navigation: {
			menu: 'stocks_configuration',
			translate: 'STOCKS.BREADCRUMBS.STOCKS_CONFIGURATION_PRODUITS_NIVEAUX',
			order: 2
		},
		search: 'STOCKS.BREADCRUMBS.STOCKS_CONFIGURATION_PRODUITS_NIVEAUX'
	});
}

Configure.$inject = ['$stateProvider'];

import MassiaNavigationConfigConstant from '../../../layout/components/massia-navigation/massia.navigation.config.constant';

export default class LoginPageController {
	static $inject = ['MassiaApplicationService', 'notification', 'ElectronService', '$state', 'VersionService'];

	app = {
		gestion: ['licenceGest', 'assets/img/massia-logo-gestion.png']
	};

	/**
	 *
	 * @param {*} MassiaApplicationService
	 * @param {*} notification
	 * @param {*} ElectronService
	 * @param {*} $state
	 * @param {import('../../../../services/version.service').default} VersionService
	 */
	constructor(MassiaApplicationService, notification, ElectronService, $state, VersionService) {
		this.MassiaApplicationService = MassiaApplicationService;
		this.notification = notification;
		this.ElectronService = ElectronService;
		this.$state = $state;
		this.versionSvc = VersionService;
	}

	$onInit() {
		this.versionSvc.getVersion().then((version) => {
			this.version = version;
		});
	}

	isApplicationActive(application) {
		return application === this.MassiaApplicationService.getApplication();
	}

	isAppEnable(key) {
		return !(typeof __configuration[key] !== 'undefined');
	}

	checkApp(key, app) {
		if (!__configuration[key]) {
			return;
		}
		this.MassiaApplicationService.setApplication(app);
	}

	enablePerformance() {
		if (!__configuration.licencePerf) {
			return '';
		}
		return 'performance.html#/login';
	}

	enableGestion() {
		if (!__configuration.licenceGest) {
			return '';
		}
		this.MassiaApplicationService.setApplication('gestion');
	}

	enableEiffagePerformance() {
		if (!__configuration.licencePerf) {
			return '';
		}
		return 'https://eiffage-performance.arcade.fr/';
	}

	enableEiffageGestion() {
		if (!__configuration.licenceGest) {
			return '';
		}
		return 'http://advgranulatsindustries.eiffage.com/MassiaClient/gestion.html#/login';
	}

	enableQse() {
		if (!__configuration.licenceQse) {
			return '';
		}
		return 'qse.html#/login';
	}

	enableLaboratoire() {
		if (!__configuration.licenceLaboratoire) {
			return '';
		}
		return 'laboratoire.html#/login';
	}
	enableCerib() {
		return __configuration.clientKey === 'CERIB';
	}
}

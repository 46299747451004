const MassiaNavigationConfigConstant = {
	help: {
		id: 'help',
		translate: 'LAYOUT.HELP.TITLE',
		items: [
			{
				id: 'help_version',
				type: 'navigation_dropdown',
				translate: '<%{ Version }%>',
				items: []
			},
			{
				id: 'help_espace_client_fr',
				translate: 'LAYOUT.HELP.ESPACE_CLIENT',
				href: 'https://www.arcade.fr/Espace_client/connexion.aspx?lng=FR',
				lng: 'fr'
			},
			{
				id: 'help_espace_client_es',
				translate: 'LAYOUT.HELP.ESPACE_CLIENT',
				href: 'https://www.arcade.fr/Espace_client/connexion.aspx?lng=ES',
				lng: 'es'
			},
			{
				id: 'help_espace_client_en',
				translate: 'LAYOUT.HELP.ESPACE_CLIENT',
				href: 'https://www.arcade.fr/Espace_client/connexion.aspx?lng=EN',
				lng: 'en'
			},
			{
				id: 'help_espace_client_nl',
				translate: 'LAYOUT.HELP.ESPACE_CLIENT',
				href: 'https://www.arcade.fr/Espace_client/connexion.aspx?lng=EN',
				lng: 'nl'
			},
			{
				id: 'help_arcade',
				translate: 'LAYOUT.HELP.ARCADE',
				href: 'https://www.arcade.fr'
			},
			{
				id: 'help_hotline_mail',
				translate: 'LAYOUT.HELP.HOTLINE_MAIL',
				href: 'mailto:hotline@arcade.fr'
			},
			/* {
                id: 'help_hotline_skype',
                translate: 'LAYOUT.HELP.HOTLINE_SKYPE',
                href: 'skype:hotline.arcade.fr?chat'
            }, */
			{
				id: 'help_telemaintenance_fr',
				translate: 'LAYOUT.HELP.TELEMAINTENANCE',
				href: 'https://get.teamviewer.com/arcadefr',
				lng: 'fr'
			},
			{
				id: 'help_telemaintenance_es',
				translate: 'LAYOUT.HELP.TELEMAINTENANCE',
				href: 'https://get.teamviewer.com/arcadees',
				lng: 'es'
			},
			{
				id: 'help_telemaintenance_en',
				translate: 'LAYOUT.HELP.TELEMAINTENANCE',
				href: 'https://get.teamviewer.com/arcadeen',
				lng: 'en'
			},
			{
				id: 'help_telemaintenance_nl',
				translate: 'LAYOUT.HELP.TELEMAINTENANCE',
				href: 'https://get.teamviewer.com/arcadeen',
				lng: 'nl'
			},
			{
				id: 'help_phone',
				type: 'navigation_dropdown',
				translate: 'LAYOUT.HELP.PHONE',
				items: []
			} /*,
            {
                id: 'help_about',
                translate: 'LAYOUT.HELP.A_PROPOS',
                href: '' // TODO brancher
            },
            {
                id: 'help_tests',
                translate: 'LAYOUT.HELP.TESTS',
                href: '' // TODO brancher
            }*/
		]
	},
	tools: {
		id: 'outils',
		icon: 'massia-icon-menu',
		position: 'right',
		translate: 'LAYOUT.NAVIGATION.OUTILS',
		translateCls: 'visible-xs-inline',
		order: 1,
		items: [
			{
				header: true,
				translate: 'LAYOUT.NAVIGATION.OUTILS'
			},
			{
				id: 'outils_historique',
				translate: 'LAYOUT.NAVIGATION.HISTORIQUE',
				rights: { domain: 'histo', right: 'read' },
				sref: 'historique.list'
			},
			{
				id: 'outils_monitoring',
				translate: 'LAYOUT.NAVIGATION.MONITORING',
				rights: { domain: 'monitoring', right: 'read' },
				sref: 'monitoring.list'
			},
			{
				id: 'outils_bet-constituant',
				translate: 'LAYOUT.NAVIGATION.BETCONSTITUANT',
				rights: { domain: 'controlepesee', right: 'read' },
				sref: 'bet-constituant.list'
			},
			{
				id: 'outils_seuil-bet-gach',
				translate: 'LAYOUT.NAVIGATION.SEUILBETGACH',
				rights: { domain: 'controlepesee', right: 'read' },
				sref: 'seuil-bet-gach.list'
			},
			{
				id: 'outils_monitoring_synchro',
				translate: 'LAYOUT.NAVIGATION.MONITORING_SYNCHRO',
				rights: { domain: 'monitoringsync', right: 'read' },
				sref: 'monitoringsynchro.list'
			},
			{
				id: 'outils_activation_synchro_site_web',
				translate: 'LAYOUT.NAVIGATION.ACTIVATION_SYNCHRO_SITE_WEB',
				rights: { domain: 'synchrosite', right: 'read', application: 'gestion' },
				sref: 'activation-site-synchro.edit'
				//clientRestriction: ['EIFFAGE'],
			},
			{
				id: 'outils_affectation_poste_pesee',
				translate: 'LAYOUT.NAVIGATION.AFFECTATION_POSTE_PESEE',
				rights: { domain: 'postepesee', right: 'read', application: 'gestion' },
				sref: 'affectation-poste-pesee.edit',
				clientRestriction: ['EIFFAGE']
			},
			{
				id: 'outils_parametrage_general',
				translate: 'LAYOUT.NAVIGATION.PARAMETRAGE_GENERAL',
				rights: { domain: 'paramprog', right: 'read' },
				sref: 'parametrage-general.edit'
			},
			{
				id: 'outils_import_donnees',
				translate: 'LAYOUT.NAVIGATION.IMPORT_DONNEES',
				rights: { domain: 'importdonnees', right: 'read', application: 'performance;gestion' },
				sref: 'importdonnees.edit'

				//clientRestriction: ['EIFFAGE'],
			},
			{
				id: 'outils_prefa_auto',
				translate: 'PLANIFICATION_JOB_PREFACTURATIONS.BREADCRUMBS.PLANIFICATION_JOB_PREFACTURATIONS_LIST',
				rights: { domain: 'prefavente', right: 'read', application: 'gestion' },
				sref: 'planification-job-prefacturations.list'
				//clientRestriction: ['EIFFAGE'],
			},
			/*{
                id: 'outils_transfert_donnees',
                translate: 'LAYOUT.NAVIGATION.TRANSFERT_DONNEES',
                sref: '#' // TODO brancher
            },
            {
                id: 'outils_taches_externes',
                translate: 'LAYOUT.NAVIGATION.TACHES_EXTERNES',
                sref: '#' // TODO brancher
            },*/
			{
				id: 'administration_portail',
				translate: 'PORTAIL.BREADCRUMBS.PORTAIL',
				rights: { domain: 'portail', right: 'read', application: 'gestion' },
				sref: 'portail.home'
			},
			{
				id: 'outils_configuration-email',
				translate: 'LAYOUT.NAVIGATION.CONFIGURATION_EMAIL',
				rights: { domain: 'configemail', right: 'read' },
				sref: 'configuration-email.list'
			},
			{
				id: 'administration_logo_site',
				translate: 'SITES.BREADCRUMBS.SITE_LOGO',
				rights: { domain: 'logosite', right: 'read', application: 'qualib' },
				sref: 'logosite'
			},
			{
				header: true,
				translate: 'LAYOUT.NAVIGATION.APPLICATIONS'
			},
			{
				id: 'applications_gestion',
				translate: 'LAYOUT.NAVIGATION.GESTION',
				href: 'gestion.html',
				href_EIFFAGE: 'http://advgranulatsindustries.eiffage.com/MassiaClient/gestion.html#/login'
			},
			{
				id: 'applications_performance',
				translate: 'LAYOUT.NAVIGATION.PERFORMANCE',
				href: 'performance.html',
				href_EIFFAGE: 'https://eiffage-performance.arcade.fr/'
			},
			{
				id: 'applications_qse',
				translate: 'LAYOUT.NAVIGATION.QSE',
				href: 'qse.html'
			},
			{
				id: 'applications_laboratoire',
				translate: 'LAYOUT.NAVIGATION.LABORATOIRE',
				href: 'laboratoire.html'
			}
		]
	},
	navigationMenus: [
		// 1
		{
			id: 'donnees',
			type: 'navigation_dropdown',
			icon: 'massia-icon-gearwheel',
			position: 'center',
			translate: 'LAYOUT.NAVIGATION.DONNEES_PARAMETRES',
			order: 1,
			items: []
		},
		//DONNEES & PARAMETRES 2
		{
			id: 'parametres',
			type: 'navigation_dropdown',
			icon: 'massia-icon-gearwheel',
			position: 'center',
			translate: 'LAYOUT.NAVIGATION.DONNEES_PARAMETRES',
			order: 2,
			items: [
				//DONNEES
				{
					id: 'donnees_parametres_donnees',
					order: 1,
					header: true,
					collapse: false,
					translate: 'LAYOUT.NAVIGATION.DONNEES_PARAMETRES_DONNEES',
					items: [
						{
							id: 'sites.list.commerciaux',
							sref: "sites.list({genre: 'commerciaux'})",
							translate: 'SITES.BREADCRUMBS.SITES_COMMERCIAUX_LIST',
							rights: { domain: 'siteutil', right: 'read', application: 'gestion' },
							order: 2
						},
						{
							id: 'sites.list.clients',
							sref: "clients.list({genre: 'clients'})",
							translate: 'SITES.BREADCRUMBS.SITES_CLIENTS_LIST',
							rights: { domain: 'siteclient', right: 'read', application: 'gestion;laboratoire' },
							order: 13
						},
						{
							id: 'chantiers.list.chantiers',
							sref: 'chantiers.list',
							translate: 'CHANTIERS.BREADCRUMBS.CHANTIERS_LIST',
							rights: { domain: 'chantiers', right: 'read', application: 'gestion;performance;laboratoire' },
							order: 14
						},
						{
							id: 'types.client.list',
							sref: 'types.clientlist',
							translate: 'TYPES.BREADCRUMBS.TYPES_CLIENT_LIST',
							rights: { domain: 'types', right: 'read', application: 'gestion' },
							order: 15
						},
						{
							id: 'types.produit.list',
							sref: 'types.produitlist',
							translate: 'TYPES.BREADCRUMBS.TYPES_PRODUIT_LIST',
							rights: { domain: 'types', right: 'read', application: 'gestion' },
							order: 10
						},
						{
							id: 'sites.list.fournisseurs',
							sref: "fournisseurs.list({genre: 'fournisseurs'})",
							translate: 'SITES.BREADCRUMBS.SITES_FOURNISSEURS_LIST',
							rights: { domain: 'sitefourn', right: 'read', application: 'gestion;performance;laboratoire' },
							order: 37
						},
						{
							id: 'chantiers.list.provenances',
							sref: 'provenances.list',
							translate: 'CHANTIERS.BREADCRUMBS.PROVENANCE_LIST',
							rights: { domain: 'chantiers', right: 'read', application: 'gestion;performance;laboratoire' },
							navigationCls: 'menu-item-separator',
							order: 37
						},
						{
							id: 'sites.list.producteurs',
							sref: "producteurs.list({genre: 'producteurs'})",
							translate: 'SITES.BREADCRUMBS.SITES_PRODUCTEURS_LIST',
							order: 8,
							rights: { domain: 'siteprod', right: 'read' }
						},
						{
							id: 'sites.list.auditeurs',
							sref: "auditeurs.list({genre: 'auditeurs'})",
							translate: 'SITES.BREADCRUMBS.SITES_AUDITEURS_LIST',
							rights: { domain: 'siteaudit', right: 'read', application: 'qse' },
							order: 2
						},
						{
							id: 'sites.list.laboratoires',
							sref: "laboratoires.list({genre: 'laboratoires'})",
							translate: 'SITES.BREADCRUMBS.SITES_LABORATOIRES_LIST',
							rights: { domain: 'sitelabo', right: 'read', application: 'laboratoire' },
							order: 2
						},

						{
							id: 'personnes.list.personne',
							sref: "personnes.list({genre: 'personne'})",
							translate: 'PERSONNES.BREADCRUMBS.PERSONNES_LIST',
							order: 6,
							navigationCls: 'menu-item-separator',
							rights: { domain: 'personnel', right: 'read', application: 'qse;laboratoire;gestion' }
						},
						{
							id: 'personnes.list.contact',
							sref: "contacts.list({genre: 'contact'})",
							translate: 'PERSONNES.BREADCRUMBS.CONTACTS_LIST',
							order: 16,
							navigationCls: 'menu-item-separator',
							rights: { domain: 'contacts', right: 'read', application: 'qse;laboratoire;gestion' }
						}
					]
				},
				//DOCUMENTS ET IMAGES
				{
					id: 'donnees_parametres_docimg',
					order: 2,
					header: true,
					collapse: false,
					translate: 'LAYOUT.NAVIGATION.DONNEES_PARAMETRES_DOC_IMG',
					items: []
				},
				//PERSONNEL
				{
					id: 'donnees_parametres_performance_personnel',
					order: 2,
					header: true,
					collapse: false,
					translate: 'LAYOUT.NAVIGATION.PERFORMANCE_PERSONNEL',
					rights: { domain: 'personnel', right: 'read', application: 'performance' },
					items: [],
					clientRestriction: [null]
				},
				//ENGINS
				{
					id: 'donnees_parametres_performance_engins',
					order: 2,
					header: true,
					collapse: false,
					translate: 'LAYOUT.NAVIGATION.PERFORMANCE_ENGINS',
					rights: { domain: 'engins', right: 'read', application: 'performance' },
					items: []
				},
				//PROCESSUS
				{
					id: 'donnees_parametres_performance_installations',
					order: 2,
					header: true,
					collapse: false,
					translate: 'LAYOUT.NAVIGATION.PERFORMANCE_INSTALLATIONS',
					rights: { domain: 'process', right: 'read', application: 'performance' },
					items: []
				},
				//PARAMETRES
				{
					id: 'donnees_parametres_parametres',
					order: 3,
					header: true,
					collapse: false,
					translate: 'LAYOUT.NAVIGATION.DONNEES_PARAMETRES_PARAMETRES',
					items: []
				},
				//CONNEXION
				{
					id: 'donnees_parametres_connexion',
					order: 4,
					header: true,
					collapse: false,
					translate: 'LAYOUT.NAVIGATION.DONNEES_PARAMETRES_CONNEXION',
					items: [
						{
							id: 'personnes.list.utilisateur',
							sref: "utilisateurs.list({genre: 'utilisateur'})",
							translate: 'UTILISATEURS.BREADCRUMBS.UTILISATEURS_LIST',
							order: 4,
							//navigationCls: "menu-item-separator",
							rights: { domain: 'utilisateurs', right: 'read' }
						}
					]
				},
				{
					id: 'donnees_parametres_no_category',
					order: 5,
					header: true,
					collapse: false,
					items: []
				}
			]
		},
		//(G) TRANSPORT 3
		{
			id: 'transport',
			type: 'navigation_dropdown',
			icon: 'massia-icon-delivery_truck',
			position: 'center',
			translate: 'LAYOUT.NAVIGATION.TRANSPORT',
			order: 3,
			items: [
				{
					id: 'transport_no_category',
					header: true,
					collapse: false,
					order: 1,
					items: [
						{
							id: 'societes.list.transporteurs',
							sref: "societes-transporteur.list({genre: '16'})",
							translate: 'SOCIETES.BREADCRUMBS.SOCIETES_TRANSPORTEUR_LIST',
							rights: { domain: 'societes', right: 'read' },
							order: 2
						},
						{
							id: 'sites.list.transporteurs',
							sref: "transporteurs.list({genre: 'transporteurs'})",
							translate: 'SITES.BREADCRUMBS.SITES_TRANSPORTEURS_LIST',
							rights: { domain: 'sitetransp', right: 'read' },
							order: 3
						},
						{
							id: 'personnes.list.chauffeur',
							sref: "chauffeurs.list({genre: 'chauffeur'})",
							translate: 'PERSONNES.BREADCRUMBS.CHAUFFEURS_LIST',
							order: 1,
							rights: { domain: 'chauffeurs', right: 'read' }
						}
					]
				},
				{
					id: 'tarification_transport',
					header: true,
					collapse: false,
					order: 2,
					translate: 'LAYOUT.NAVIGATION.TARIFICATION_TRANSPORT',
					items: []
				},
				{
					id: 'vehicule',
					header: true,
					collapse: false,
					order: 3,
					translate: 'LAYOUT.NAVIGATION.VEHICULE',
					items: []
				},
				{
					id: 'transport_plannings',
					header: true,
					collapse: false,
					order: 4,
					translate: 'LAYOUT.NAVIGATION.TRANSPORT_PLANNINGS',
					items: []
				}
			]
		},
		//(G) GESTION COMMERCIALE 4
		{
			id: 'gestion_commerciale',
			type: 'navigation_dropdown',
			icon: 'glyphicon glyphicon-stats',
			position: 'center',
			translate: 'LAYOUT.NAVIGATION.GESTION_COMMERCIALE',
			order: 4,
			items: [
				{
					id: 'gestion_commerciale_commercial',
					header: true,
					collapse: false,
					order: 3,
					//translate: 'LAYOUT.NAVIGATION.GESTION_COMMERCIALE_COMMERCIAL',
					items: []
				}
			]
		},
		//(G) PESEES ET FACTURATION 5
		{
			id: 'pesees_livraisons',
			type: 'navigation_dropdown',
			icon: 'glyphicon glyphicon-scale',
			position: 'center',
			header: true,
			collapse: false,
			translate: 'LAYOUT.NAVIGATION.PESEES_FACTURATIONS',
			order: 5,
			items: [
				{
					id: 'pesees_livraisons',
					header: true,
					collapse: false,
					order: 1,
					translate: 'LAYOUT.NAVIGATION.PESEES_LIVRAISONS',
					items: []
				},
				{
					id: 'facturation',
					header: true,
					collapse: false,
					order: 2,
					translate: 'LAYOUT.NAVIGATION.FACTURATION',
					items: []
				},
				{
					id: 'inertes',
					header: true,
					collapse: false,
					order: 3,
					translate: 'LAYOUT.NAVIGATION.INERTE',
					items: []
				},
				{
					id: 'registres',
					header: true,
					collapse: false,
					order: 4,
					translate: 'LAYOUT.NAVIGATION.REGISTRES',
					items: []
				}
			]
		},
		//(G) STOCKS 6
		{
			id: 'stocks',
			type: 'navigation_dropdown',
			icon: 'glyphicon glyphicon-briefcase',
			position: 'center',
			translate: 'LAYOUT.NAVIGATION.STOCKS',
			order: 6,
			items: [
				{
					id: 'stocks_no_category',
					header: true,
					collapse: false,
					order: 1,
					//translate: 'LAYOUT.NAVIGATION.GESTION_COMMERCIALE_COMMERCIAL',
					items: []
				},
				{
					id: 'stocks_cloture',
					header: true,
					collapse: false,
					order: 2,
					translate: 'LAYOUT.NAVIGATION.STOCK_CLOTURE',
					items: [
						/*{
                            id: "stock.cloture.jour",
                            sref: "stock.cloture.jour",
                            translate: "GESTION.BREADCRUMBS.CLOTURE_JOURNALIERE",
                            order: 1,
                            rights: {domain: 'cloturejour', right: 'read'},
                        }*/
					]
				},
				{
					id: 'stocks_parametrage',
					header: true,
					collapse: false,
					order: 3,
					translate: 'LAYOUT.NAVIGATION.PARAMETRAGE',
					items: []
				},
				{
					id: 'stocks_configuration',
					header: true,
					collapse: false,
					order: 3,
					translate: 'LAYOUT.NAVIGATION.CONFIGURATION',
					items: [],
					rights: { domain: 'stockconfig', right: 'read' }
				}
			]
		},
		//(P) PERSONNEL PERFORMANCE 5
		{
			id: 'performance_personnel',
			type: 'navigation_dropdown',
			icon: 'glyphicon glyphicon-user',
			position: 'center',
			translate: 'LAYOUT.NAVIGATION.PERFORMANCE_PERSONNEL',
			clientRestriction: [null],
			order: 5,
			items: [
				{
					id: 'performance_personnel_donnees',
					order: 1,
					header: true,
					collapse: false,
					items: []
				},
				{
					id: 'performance_personnel_saisies',
					order: 2,
					header: true,
					collapse: false,
					items: []
				}
			]
		},
		//(P) ENGINS PERFORMANCE 6
		{
			id: 'performance_engins',
			type: 'navigation_dropdown',
			icon: 'massia-icon-dumper2',
			position: 'center',
			translate: 'LAYOUT.NAVIGATION.PERFORMANCE_ENGINS',
			order: 6,
			items: [
				{
					id: 'performance_engins_donnees',
					order: 1,
					header: true,
					collapse: false,
					items: []
				},
				{
					id: 'performance_engins_saisies',
					order: 2,
					header: true,
					collapse: false,
					items: []
				}
			]
		},
		//(P) INSTALLATIONS PERFORMANCE 7
		{
			id: 'performance_installations',
			type: 'navigation_dropdown',
			icon: 'glyphicon glyphicon-file',
			position: 'center',
			translate: 'LAYOUT.NAVIGATION.PERFORMANCE_INSTALLATIONS',
			order: 7,
			items: [
				{
					id: 'performance_installations_donnees',
					order: 1,
					header: true,
					collapse: false,
					items: []
				},
				{
					id: 'performance_installations_saisies',
					order: 2,
					header: true,
					collapse: false,
					items: []
				}
			]
		},
		//(P) TABLEAU DE BORD PERFORMANCE 8
		{
			id: 'massia_performance_dashboard',
			type: 'navigation',
			icon: 'glyphicon glyphicon-dashboard',
			position: 'center',
			sref: 'dashboard.list',
			translate: 'LAYOUT.NAVIGATION.DASHBOARD_PERFORMANCE',
			rights: { domain: 'dashboard', right: 'read' },
			order: 8
		},
		//(Q) GENERAL QSE 6
		{
			id: 'massia_qse_general',
			type: 'navigation_dropdown',
			icon: 'glyphicon glyphicon-file',
			position: 'center',
			translate: 'LAYOUT.NAVIGATION.GENERAL',
			order: 6,
			items: [
				{
					id: 'qse_general_no_category',
					header: true,
					collapse: false,
					order: 1,
					items: []
				}
			]
		},
		//(Q) ENVIRONNEMENT  7
		{
			id: 'environnement',
			type: 'navigation_dropdown',
			icon: 'glyphicon glyphicon-leaf',
			position: 'center',
			translate: 'LAYOUT.NAVIGATION.ENVIRONNEMENT',
			order: 7,
			items: [
				{
					id: 'environnement_theme_mes',
					header: true,
					collapse: false,
					order: 1,
					translate: 'LAYOUT.NAVIGATION.ENV_THEMES_MESURABLES',
					items: []
				},
				{
					id: 'environnement_dechets',
					header: true,
					collapse: false,
					order: 2,
					translate: 'LAYOUT.NAVIGATION.ENV_DECHETS',
					items: []
				},
				{
					id: 'environnement_tirs_mine',
					header: true,
					collapse: false,
					order: 3,
					translate: 'LAYOUT.NAVIGATION.ENV_TIRS_MINE',
					items: []
				}
			]
		},
		//(L) ESSAIS NORMES 6
		{
			id: 'laboratoire_essais_norme',
			type: 'navigation_dropdown',
			icon: 'glyphicon glyphicon-book',
			position: 'center',
			translate: 'LAYOUT.NAVIGATION.ESSAIS_NORMES',
			order: 6,
			items: [
				{
					id: 'essais_normes_seul',
					order: 1,
					header: true,
					collapse: false,
					items: []
				},
				{
					id: 'specificites_gran',
					header: true,
					collapse: false,
					order: 2,
					translate: 'LAYOUT.NAVIGATION.SPECIFICITES_GRANULAT',
					items: []
				},
				{
					id: 'specificites_beton',
					header: true,
					collapse: false,
					order: 3,
					translate: 'LAYOUT.NAVIGATION.SPECIFICITES_BETON',
					items: []
				},
				{
					id: 'specificites_enrobes',
					header: true,
					collapse: false,
					order: 5,
					translate: 'LAYOUT.NAVIGATION.SPECIFICITES_ENROBES',
					items: []
				},
				{
					id: 'specificites_ciment',
					header: true,
					collapse: false,
					order: 6,
					translate: 'LAYOUT.NAVIGATION.SPECIFICITES_CIMENT',
					items: []
				}
			]
		},
		//(L) LABORATOIRE SAISIE 7
		{
			id: 'laboratoire_aa',
			type: 'navigation_dropdown',
			icon: 'glyphicon glyphicon-edit',
			position: 'center',
			translate: 'LAYOUT.NAVIGATION.PRELEVEMENTS',
			order: 7,
			items: [
				{
					id: 'laboratoire_donnees',
					header: true,
					collapse: false,
					order: 1,
					translate: 'LAYOUT.NAVIGATION.DONNEES_PARAMETRES',
					items: []
				},
				{
					id: 'laboratoire_prelevements',
					header: true,
					collapse: false,
					order: 2,
					items: []
				}
			]
		},
		//(L) LABORATOIRE DOSSIER 8
		{
			id: 'laboratoire_dossier',
			type: 'navigation_dropdown',
			icon: 'glyphicon glyphicon-folder-open',
			position: 'center',
			translate: 'LAYOUT.NAVIGATION.DOSSIERS',
			order: 8,
			items: [
				{
					id: 'laboratoire_dossiers',
					header: true,
					collapse: false,
					order: 1,
					items: []
				}
			]
		},
		//(L) LABORATOIRE LABORATOIRE 9
		{
			id: 'laboratoire_laboratoire',
			type: 'navigation_dropdown',
			icon: 'glyphicon glyphicon-education',
			position: 'center',
			translate: 'LAYOUT.NAVIGATION.LABORATOIRE',
			order: 9,
			items: [
				{
					id: 'metrologie',
					header: true,
					collapse: false,
					order: 4,
					translate: 'LAYOUT.NAVIGATION.METROLOGIE',
					items: []
				},
				{
					id: 'laboratoire_prod',
					header: true,
					collapse: false,
					order: 5,
					items: []
				}
			]
		},
		//(Q) SECURITE PERSONNEL 8
		{
			id: 'securite_personnel',
			type: 'navigation_dropdown',
			icon: 'glyphicon glyphicon-user',
			position: 'center',
			translate: 'LAYOUT.NAVIGATION.SECURITE_PERSONNEL',
			order: 8,
			items: [
				{
					id: 'securite_personnel_donnees',
					header: true,
					collapse: false,
					order: 1,
					translate: 'LAYOUT.NAVIGATION.DONNEES_PARAMETRES_DONNEES',
					items: []
				},
				{
					id: 'securite_personnel_saisies',
					header: true,
					collapse: false,
					order: 2,
					translate: 'LAYOUT.NAVIGATION.SAISIES',
					items: []
				},
				{
					id: 'securite_personnel_rh',
					header: true,
					collapse: false,
					order: 3,
					translate: 'LAYOUT.NAVIGATION.RH',
					items: []
				}
			]
		},
		//(Q) SECURITE MATERIEL 9
		{
			id: 'securite_materiel',
			type: 'navigation_dropdown',
			icon: 'glyphicon glyphicon-alert',
			position: 'center',
			translate: 'LAYOUT.NAVIGATION.SECURITE_MATERIEL',
			order: 9,
			items: [
				{
					id: 'securite_materiel_donnees',
					header: true,
					collapse: false,
					order: 1,
					translate: 'LAYOUT.NAVIGATION.DONNEES_PARAMETRES_DONNEES',
					items: []
				},
				{
					id: 'securite_materiel_saisies',
					header: true,
					collapse: false,
					order: 2,
					translate: 'LAYOUT.NAVIGATION.SAISIES',
					items: []
				}
			]
		},
		//(Q) FONCIER 10
		/* {
            id: 'foncier',
            type: 'navigation_dropdown',
            icon: 'glyphicon glyphicon-picture',
            position: 'center',
            rights: { application: 'qse' },
            translate: 'LAYOUT.NAVIGATION.FONCIER',
            order: 10,
            items: [
                {
                    id: 'foncier_no_category',
                    header: true,
                    collapse: false,
                    order: 1,
                    items: [
                        {
                            id: 'sites.list.proprietaires',
                            sref: "proprietaires.list({genre: 'proprietaires'})",
                            translate: 'SITES.BREADCRUMBS.SITES_PROPRIETAIRES_LIST',
                            rights: { domain: 'siteproprio', right: 'read', application: 'qse' },
                            order: 2
                        },
                        {
                            id: 'sites.list.locataires',
                            sref: "locataires.list({genre: 'locataires'})",
                            translate: 'SITES.BREADCRUMBS.SITES_LOCATAIRES_LIST',
                            rights: { domain: 'sitelocat', right: 'read', application: 'qse' },
                            order: 4
                        }
                    ]
                }
            ]
        }, */
		//(Q) SUIVI EXIGENCES 11
		{
			id: 'suivi_exigences',
			type: 'navigation_dropdown',
			icon: 'glyphicon glyphicon-hand-right',
			position: 'center',
			translate: 'LAYOUT.NAVIGATION.SUIVI_EXIGENCES',
			order: 11,
			items: [
				{
					id: 'suivi_exigences_no_category',
					header: true,
					collapse: false,
					order: 1,
					items: []
				}
			]
		},

		//(G P) ACCES MASSIA SYNTHESES 20
		/* {
            id: 'massia_syntheses',
            type: 'navigation',
            rights: { domain: 'massiasynthapp', right: 'read' },
            icon: 'massia-icon-chart_column',
            translate: 'LAYOUT.NAVIGATION.SYNTHESE',
            sref: 'massiasyntheses',
            order: 20,
        }, */
		// Synthèse 2.0
		{
			id: 'massia_syntheses',
			type: 'navigation',
			/* rights: { domain: 'synthadmin', right: 'read' }, */
			icon: 'massia-icon-chart_column',
			translate: 'LAYOUT.NAVIGATION.SYNTHESE',
			order: 20,
			items: [
				{
					id: 'massia_synth_synth',
					order: 3,
					header: true,
					collapse: false,
					items: [
						{
							id: 'massia_syntheses_old',
							type: 'navigation',
							rights: { domain: 'massiasynthapp', right: 'read' },
							translate: 'LAYOUT.NAVIGATION.SYNTHESE_OLD',
							sref: 'massiasyntheses',
							order: 0
						}
					]
				}
			]
		},
		//(L Q) ANALYSES (synthèses labo/QSE) 15
		{
			id: 'analyses',
			type: 'navigation_dropdown',
			icon: 'glyphicon glyphicon-equalizer',
			// icon: 'glyphicon glyphicon-tasks',
			position: 'center',
			translate: 'LAYOUT.NAVIGATION.ANALYSE',
			order: 15,
			items: [
				{
					id: 'analyse_synthese_admin',
					header: true,
					collapse: false,
					order: 1,
					translate: 'LAYOUT.NAVIGATION.DONNEES_PARAMETRES',
					items: []
				},
				{
					id: 'analyse_synthese_ce',
					header: true,
					collapse: false,
					order: 2,
					translate: 'LAYOUT.NAVIGATION.DDP_DOCCE',
					items: []
				},
				{
					id: 'analyse_direct',
					order: 3,
					header: true,
					collapse: false,
					items: []
				}
			]
		},
		//CALENDRIER 16
		{
			id: 'calendar_nav',
			type: 'navigation_dropdown',
			icon: 'glyphicon glyphicon-calendar',
			rights: { domain: 'calendar', right: 'read' },
			position: 'center',
			translate: 'LAYOUT.NAVIGATION.CALENDAR',
			order: 16,
			items: [
				{
					id: 'calendar',
					order: 1,
					header: true,
					collapse: false,
					items: []
				},
				{
					id: 'calend_specificites_enrobes',
					header: true,
					collapse: false,
					order: 5,
					translate: 'LAYOUT.NAVIGATION.SPECIFICITES_ENROBES',
					items: []
				}
			]
		}
	]
};

export default MassiaNavigationConfigConstant;

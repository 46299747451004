import { LoDashStatic } from 'lodash';
import { GammeService } from '../../../../../../../core/http/gamme.service';
import { SerieTamisService } from '../../../../../../../core/http/serie-tamis.service';
import { SiteService } from '../../../../../../../core/http/site.service';
import { TypeService } from '../../../../../../../core/http/type.service';
import { FilterType } from '../../../../../../../core/interface/FilterProperty';
import { Gamme } from '../../../../../../../core/models/gamme/Gamme';
import { Tamis } from '../../../../../../../core/models/tamis/Tamis';
import { SiteGenre } from '../../../../../../../core/enum/SiteGenre';
import CodeAutoService from '../../../../../code-automatique/services/code-automatique.service';
import MassiaRightsService from '../../../../../../massia/authentication/services/massia.rights.service';
import { CreateUpdateProduit } from '../../../../../../../core/models/produit/UpdateProduit';
import { ProductService } from '../../../../../../../core/http/product.service';
import MassiaApplicationService from '../../../../../../massia/layout/components/massia-application/massia.application.service';

export class EnteteController {
	produit: any;
	app: string;
	siteSvc: SiteService;
	typeSvc: TypeService;
	gammeSvc: GammeService;
	serieTamSvc: SerieTamisService;
	codeAutoSvc: CodeAutoService;
	MassiaRightsService: MassiaRightsService;
	massiaAppSvc: MassiaApplicationService;
	ProduitsService: any;
	productSvc: ProductService;
	ModalService: any;
	$translate: any;
	notification: any;
	sites: any = [];
	types: any = [];
	seriePetitD: Tamis[] = [];
	serieGrandD: Tamis[] = [];
	gammes: Gamme[] = [];
	isEqiom: boolean;
	isMD: boolean;
	isDesactivationProductRight: boolean;
	$scope: ng.IScope;
	unregister: any;
	_: any;

	/* @ngInject */
	constructor(
		SiteService: SiteService,
		TypeService: TypeService,
		GammeService: GammeService,
		$scope: ng.IScope,
		SerieTamisService: SerieTamisService,
		CodeAutomatiqueService: CodeAutoService,
		MassiaRightsService: MassiaRightsService,
		ProduitsService: any,
		ProductService: ProductService,
		ModalService: any,
		$translate: any,
		notification: any,
		_: LoDashStatic,
		MassiaApplicationService: MassiaApplicationService
	) {
		this.siteSvc = SiteService;
		this.typeSvc = TypeService;
		this.gammeSvc = GammeService;
		this.serieTamSvc = SerieTamisService;
		this.$scope = $scope;
		this.codeAutoSvc = CodeAutomatiqueService;
		this.MassiaRightsService = MassiaRightsService;
		this.ProduitsService = ProduitsService;
		this.productSvc = ProductService;
		this.ModalService = ModalService;
		this.$translate = $translate;
		this.notification = notification;
		this._ = _;
		this.massiaAppSvc = MassiaApplicationService;
	}

	$onInit() {
		this.initSelect();
		this.loadProducteurs();
		this.loadTypes();
		this.loadGamme();
		this.loadSerieDim();
		this.checkCodeAuto();
		this.produit.petitD && this.seriePetitD.push(this.produit.petitD);
		this.produit.grandD && this.serieGrandD.push(this.produit.grandD);
		if (!this.produit.petitD) {
			this.produit.petitD = {};
		}
		if (!this.produit.grandD) {
			this.produit.grandD = {};
		}
		this.unregister = this.$scope.$watch(
			() => this.produit.type,
			() => {
				this.loadProducteurs();
			},
			true
		);

		this.isMD = this.produit.type.code === 'TEWIL100';
		this.isDesactivationProductRight = false;
		if (this.produit.code)
			//permet de vérifier le code lors de la duplication
			this.checkCodeUnicity(this.produit.code);
	}

	$onDestroy() {
		this.unregister();
	}

	initSelect() {
		this.sites = this.produit.producteurs;
		this.types = [];
		this.types.push(this.produit.type);
	}

	async loadMixDesign() {}

	async loadSerieDim() {
		if (this.produit.type?.typeProduit?.serieDim) {
			const serie = await this.serieTamSvc.getOne(this.produit.type.typeProduit.serieDim.id);
			this.seriePetitD = this._.cloneDeep(serie.tamis);
			this.serieGrandD = this._.cloneDeep(serie.tamis);
		}
	}

	async loadGamme() {
		if (this.produit.gamme?.id > 0) {
			this.gammes = [this.produit.gamme];
		}
		const gammes = await this.gammeSvc.getAll();
		this.gammes = gammes.items.filter((x) => x.famille === null || x.famille.id === this.produit.type?.famille?.id);
	}

	async loadProducteurs() {
		try {
			const filters = [
				{
					criterion: 'genre',
					type: FilterType.CONTAINS,
					value: SiteGenre.Producteur
				}
			];
			if (this.produit.type?.famille?.id) {
				filters.push({
					criterion: 'familles.famille.id',
					type: FilterType.EQUALS,
					value: this.produit.type?.famille?.id
				});
			}
			const sites = await this.siteSvc.getAll(filters, null, null, null, {
				application: this.massiaAppSvc.application
			});

			this.sites = sites.items.map((e) => {
				e.isActivated = true;
				return e;
			});
		} catch (ex) {
			console.log(ex);
		}
	}

	async loadTypes() {
		const types = await this.typeSvc.getAll([
			{
				criterion: 'domaine.code',
				type: FilterType.EQUALS,
				value: 'produits'
			}
		]);
		//en création ne pas proposer les types avec identification auto
		if (this.produit.id) this.types = types.items;
		else this.types = types.items.filter((x) => x.typeProduit === null || x.typeProduit.codeNormalisable !== true);
	}

	// selectTamis(model: Tamis, output: Tamis[]) {
	// 	if (output.length > 0) {
	// 		model = output[0];
	// 	} else {
	// 		model = null;
	// 	}
	// }

	async checkCodeUnicity(code: string) {
		if (!code) {
			this.produit.isCodeExists = null;
			return;
		}
		try {
			if (code.match(/^[a-zA-Z0-9_|]*$/)) {
				this.produit.isCodeExists = await this.ProduitsService.codeExists(code);
			}
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async checkCodeAuto() {
		try {
			const code = await this.codeAutoSvc.getCodeAutomatique(this.app);
			const res = code.find((x: any) => x.domaineCode === 'Produits');
			this.produit.isCodeAutomatique = res.estCodeAutomatique;
		} catch (err) {
			console.error(err);
		}
	}

	// Quand on selectionne le type
	async selectType(selectedType: any) {
		if (selectedType.length > 0) {
			this.produit.type = selectedType[0];
		}
		this.produit.gamme = {
			id: 0,
			code: null,
			libelle: null
		};
		this.loadGamme();
		this.loadSerieDim();
	}

	selectProducteur(selectedProducteur: any) {
		for (let i = 0; i < selectedProducteur.length; i++) {
			const lienExist = this.produit.producteurs.filter((x: any) => x.id == selectedProducteur[i].id);
			if (lienExist && lienExist.length > 0) {
				selectedProducteur[i].familleBeton = lienExist[0].familleBeton;
				selectedProducteur[i].isActivated = lienExist[0].isActivated;
			} else {
				selectedProducteur[i].isActivated = true;
			}
		}
		this.produit.producteurs = selectedProducteur;
	}

	selectGamme(selectedGamme: any) {
		if (selectedGamme.length > 0) {
			this.produit.gamme = selectedGamme[0];
		} else {
			this.produit.gamme = null;
		}
	}

	hasOptionalInfo() {
		return this.produit.type?.typeProduit?.granulometrie > 0 || (this.app === 'gestion' && this.produit.type?.famille?.code === 'INER');
	}

	async changeActiveStatus(actif: any) {
		if (!actif) {
			const modalInstance = this.ModalService.confirm({
				modalTitle: this.$translate.instant('PRODUITS.DESACTIVE.TITLE', { code: this.produit.libelle }),
				modalMsg: this.$translate.instant('PRODUITS.DESACTIVE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(
				async () => {
					this.produit.isEnabled = actif;
					await this.ProduitsService.desactiveProduit(this.produit.id);
					this.notification.success(this.$translate.instant('PRODUITS.DESACTIVE.SUCCESS_DESACTIVE'));
					//SP 15/02/21 pas beau mais je ne sais pas comment recharger la page sinon
					const produit = await this.productSvc.getOne(this.produit.id, {
						params: {
							application: this.app
						}
					});
					this.produit = <CreateUpdateProduit>produit;
				},
				async () => {
					this.produit.isEnabled = !actif;
				}
			);
		} else {
			await this.ProduitsService.activeProduit(this.produit.id);
			this.notification.success(this.$translate.instant('PRODUITS.DESACTIVE.SUCCESS_REACTIVE'));
		}
	}
}

export default {
	bindings: {
		produit: '=',
		app: '<',
		isOpen: '=',
		code: '<'
	},
	controller: EnteteController,
	template: require('./entete.html')
};

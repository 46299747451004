export default class PortailRequestController {
	constructor($scope, $state, notification, UserRequestService, DomainPortailService) {
		this.$scope = $scope;
		this.$state = $state;
		this.notification = notification;
		this.UserRequestService = UserRequestService;
		this.DomainPortailService = DomainPortailService;
	}
	selectedReq = null;
	domaines = [];

	async $onInit() {
		await this.loadUserRequest();
	}

	selectReq(req) {
		this.selectedReq = req;
		this.selectedReq.access = this.mapDomaines(this.selectedReq.access);
		this.selectedReq.user.access = this.mapDomaines(this.selectedReq.user.access);
	}

	mapDomaines(listAccess) {
		this.domaines.items.forEach((domaine) => {
			const found = listAccess.some((access) => access.idDomain === domaine.id);

			if (!found) {
				listAccess.push({
					codeDomain: domaine.code,
					libelleDomain: domaine.libelle,
					idDomain: domaine.id,
					accessLevel: 0
				});
			}
		});
		return listAccess;
	}

	async loadUserRequest() {
		try {
			this.request = (await this.UserRequestService.getAll()).data || {};
			this.domaines = (await this.DomainPortailService.getAll()).data || [];
		} catch (error) {
			this.notification.error(error.data.message);
		}
	}

	async validModification(req) {
		try {
			const res = await this.UserRequestService.deleteRequest(req, true);
			this.notification.success('PORTAIL.USER.UPDATE_REQUEST_VALID');

			if (res.data == 'Email Error') {
				this.notification.warning('PORTAIL.USER.UPDATE_REQUEST_MAIL_ERROR');
			} else {
				this.notification.success('PORTAIL.USER.UPDATE_REQUEST_MAIL');
			}
			this.selectedUser = null;
			this.UserRequestService.loadAll();
			this.loadUserRequest();
		} catch (err) {
			console.log(err);
			this.notification.error(err.message);
		}
	}

	async denyModification(req) {
		try {
			const res = await this.UserRequestService.deleteRequest(req, false);

			this.notification.success('PORTAIL.USER.UPDATE_REQUEST_REFUS');

			if (res.data == 'Email Error') {
				this.notification.warning('PORTAIL.USER.UPDATE_REQUEST_MAIL_ERROR');
			} else {
				this.notification.success('PORTAIL.USER.UPDATE_REQUEST_MAIL');
			}
			this.selectedUser = null;
			this.UserRequestService.loadAll();
			this.loadUserRequest();
		} catch (err) {
			console.log(err);
			this.notification.error(err.message);
		}
	}
}

PortailRequestController.$inject = ['$scope', '$state', 'notification', 'UserRequestService', 'DomainPortailService'];

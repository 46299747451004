// loading angular

import configurationModule from './_config';
import typeBennesModule from './type-bennes';
import paiementsModule from './paiements';
import homeModule from './home';
import typeBasculesModule from './type-bascules';
import vehiculesModule from './vehicules';
import prestationsModule from './prestations';
import transportsModule from './transports';
import controlesModule from './controles';
import bonsModule from './bons';
import offresModule from './offres';
import commandesModule from './commandes';
import banquesModule from './banques';
import distanciersModule from './distanciers';
import tarifsModule from './tarifs';
import documentModule from '../common/documents';
import dapModule from './dap';
import inerteDeclarationAnnuelle from './inerte-declaration-annuelle';
import inerteRegistreAdmission from './inerte-registre-admission';
import stocksModule from './stocks';
import stocksCloturesModule from './stocks-clotures';
import testApiModule from './test-api';
import remisesFinAnneeModule from './remises-fin-annee';
import suiviDroitBitumeModule from './suivi-droit-bitume';
import indicesRevisionModule from './indices-revision';
import badgesModule from './badges';
import grillesZonesKilometriquesModule from './grilles-zones-kilometriques';
import zonesModule from './zones';
import carnetsModule from './carnets';
import carnetsSuiviModule from './carnets-suivi';
import demandesLivraisonsModule from './plannings/demandes-livraisons';
import tracteursModule from './tracteurs';
import bennesModule from './bennes';
import equipagesModule from './equipages';
import dispatchingModule from './plannings/dispatching';
import feuilleRouteModule from './plannings/feuille-route';
import previsionnelTourneeModule from './plannings/previsionnel-tournee';
import livraisonPlanifieeLivreeModule from './plannings/livraison-planifiee-livree';
import synthesesModule from './syntheses';
import monitoringSynchroModule from './monitoring-synchro';
import planImpressionPrixModule from './plan-impression-prix';
import chantiersGrillesModule from './chantiers-grilles';
import encoursModule from './encours';
import activationSiteSynchro from './activation-site-synchro';
import affectationPostePesee from './affectation-poste-pesee';
import etatReglementsModule from './etat-reglements';
import tgapExclure from './tgap-exclure';
import motifRefusAdmission from './motif-refus-admission';
import planificationJobPrefacturation from './planification-job-prefacturations';
import importBlExternes from './import-bl-externes';
import grilleTarifaires from './grille-tarifaires';
import registres from './registres';
import nomenclaturesModule from './nomenclatures';
import stocksConfigurationProduits from './stocks-configuration-produits';
import stocksConfigurationProduitsNiveaux from './stocks-configuration-produits-niveaux';

const moduleName = 'app.massia.gestion';
angular.module(moduleName, [
	configurationModule,
	typeBennesModule,
	paiementsModule,
	homeModule,
	typeBasculesModule,
	vehiculesModule,
	prestationsModule,
	transportsModule,
	controlesModule,
	bonsModule,
	offresModule,
	commandesModule,
	banquesModule,
	distanciersModule,
	tarifsModule,
	documentModule,
	dapModule,
	inerteDeclarationAnnuelle,
	inerteRegistreAdmission,
	stocksModule,
	stocksCloturesModule,
	testApiModule,
	remisesFinAnneeModule,
	suiviDroitBitumeModule,
	indicesRevisionModule,
	badgesModule,
	grillesZonesKilometriquesModule,
	zonesModule,
	carnetsModule,
	carnetsSuiviModule,
	demandesLivraisonsModule,
	tracteursModule,
	bennesModule,
	dispatchingModule,
	feuilleRouteModule,
	equipagesModule,
	previsionnelTourneeModule,
	livraisonPlanifieeLivreeModule,
	synthesesModule,
	monitoringSynchroModule,
	planImpressionPrixModule,
	chantiersGrillesModule,
	encoursModule,
	activationSiteSynchro,
	affectationPostePesee,
	tgapExclure,
	motifRefusAdmission,
	planificationJobPrefacturation,
	importBlExternes,
	etatReglementsModule,
	grilleTarifaires,
	registres,
	nomenclaturesModule,
	stocksConfigurationProduits,
	stocksConfigurationProduitsNiveaux
]);

export default moduleName;

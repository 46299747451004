export default class DapPrintModalController {
	/* @ngInject */
	constructor($scope, $state, $stateParams, DAPsService, SettingPortailService) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.sendToPortail = false;
		this.fileName = '';
		this.disabledFileNameField = false;
		this.DAPsService = DAPsService;
		this.SettingPortailService = SettingPortailService;
	}

	async $onInit() {
		if (this.resolve.dapDocSetting.data) {
			if (this.resolve.dapDocSetting.data.filenameAuto) {
				this.fileName = (
					await this.SettingPortailService.getAutoFilename(this.resolve.dap.id, 'DAP', this.resolve.dapDocSetting.data.format)
				).data;
				this.disabledFileNameField = true;
			}
		}
	}

	async print() {
		let trySucceeded = false;

		const resultat = await this.DAPsService.print(this.resolve.dap.id, this.sendToPortail, this.fileName);

		console.log();
		const data = resultat.data;
		let headers = resultat.headers;

		headers = headers();

		const contentType = headers['content-type'];

		const linkElement = document.createElement('a');
		try {
			const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
			const url = window.URL.createObjectURL(blob);
			linkElement.setAttribute('href', url);
			console.log(this.fileName);
			if (!this.fileName) {
				if (this.resolve.dap.numero) {
					this.fileName = this.resolve.dap.numero;
				} else {
					this.fileName = this.resolve.dap.entete.numero;
				}
				console.log(this.fileName);
			}
			linkElement.setAttribute('download', this.fileName + '.pdf');

			const clickEvent = new MouseEvent('click', {
				view: window,
				bubbles: true,
				cancelable: false
			});
			linkElement.dispatchEvent(clickEvent);
			trySucceeded = true;
		} catch (ex) {
			//console.log(ex);
		}
		if (trySucceeded) {
			this.close({ $value: { isOk: false } });
		}
	}

	cancel() {
		this.close({ $value: { isOk: false } });
	}
}

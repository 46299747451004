export default class DapAttenteGridController {
	/* @ngInject */
	constructor($scope, $state, $stateParams, $translate, ModalService, PaginationService, DAPsService, notification, $uibModal, $transitions) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.$translate = $translate;
		this.ModalService = ModalService;
		this.PaginationService = PaginationService;
		this.DAPsService = DAPsService;
		this.notification = notification;
		this.$uibModal = $uibModal;
		this.$transitions = $transitions;
		this.daps = [];
	}

	async $onInit() {
		this.daps = await this.DAPsService.GetDapAttenteListByDemandeur();
		console.log(this.daps);
	}

	async selectDAP(dap) {
		this.DAPsService.currentDAPAttente = dap;
		this.$state.go('daps.attente', { id: dap.id });
	}
}

import PersonneFormController from './personnes.form.controller';
import PersonneFormTemplate from './personnes.form.html';

export default {
	bindings: {
		id: '<',
		data: '='
	},
	template: PersonneFormTemplate,
	controller: PersonneFormController
};

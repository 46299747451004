import OffreProducteurLigneProduit from './offre.producteur.ligne.produit.model';
import OffreProducteurLignePrestation from './offre.producteur.ligne.prestation.model';
import OffreProducteurLigneTransport from './offre.producteur.ligne.transport.model';

export default class OffreProducteurController {
	static $inject = ['$stateParams', 'OffresCommunicationService', 'OffresService', '_', 'notification', '$filter'];

	constructor($stateParams, OffresCommunicationService, OffresService, _, notification, $filter) {
		this.$stateParams = $stateParams;
		this.OffresCommunicationService = OffresCommunicationService;
		this.OffresService = OffresService;
		this.notification = notification;
		this._ = _;
		this.addZone = false;
		this.newProduitType = 'prestation';
		this.produitsDisponibles = [];
		this.sitesProducteurs = [];
		this.produitsDisponibles = [];
		this.produits = [];
		this.$filter = $filter;
		this.isTransported = false;
		this.tvaList = [];
	}

	async $onInit() {
		this.dateEcheanceFormule = null;
		this.messageFormule = 'Masquer produits avec formules expirées ';
		this.bennes = await this.OffresService.getBennes();
		this.selectedLigneType = '0';
		this.clientid = this.offre.demande.idClient;
		this.initProduit();

		//SP 28/12/17 trier sitesProducteurs
		this.sitesProducteurs = this.$filter('orderBy')(this.sitesProducteurs, 'libelle');
		const prestationsList = await this.OffresService.getPrestations();
		// On retire les prestations pour les indices de révision
		this.prestations = prestationsList.filter((prestation) => prestation.modeDeclenchement < 3);
		// const prestaRechargements = prestationsList.filter((prestation) => prestation.modeDeclenchement == 6);
		// this.prestations = this.prestations.concat(prestaRechargements);
		this.transports = await this.OffresService.getTransports();

		const isChantier = true;
		const siteComm = await this.OffresService.getSiteById(this.producteur.idSiteCommercial);
		if (siteComm.lieuId != null && this.proposition.idLieu != null) {
			const lieuDeparts = [siteComm.lieuId];
			const lieuArrivee = [this.proposition.idLieu];
			const distancier = await this.OffresService.getDistancier(isChantier, lieuDeparts, lieuArrivee);
			this.offreZone = null;
			if (distancier.lignes.length > 0) {
				if (distancier.lignes[0].valeurs[0]) {
					this.offreZone = distancier.lignes[0].valeurs[0].idZone;
				}
			}
		}

		this.tvaList = await this.OffresService.getTvaList();
		this.recalculeLignes();
	}

	async initProduit() {
		this.produits = await this.OffresService.getProduitsLies({
			societeId: this.offre.demande.idSociete,
			clientId: this.clientid,
			siteCommercialId: this.producteur.idSiteCommercial,
			date: this.dateEcheanceFormule,
			entreeSortie: this.producteur.typeES
		});

		//SP 28/12/17 trier produits
		this.produits = this.$filter('orderBy')(this.produits, 'produitLibelle');

		this.sitesProducteurs = [];

		angular.forEach(
			this.produits,
			function (produit) {
				if (this._.findIndex(this.sitesProducteurs, { id: produit.producteurId }) == -1) {
					this.sitesProducteurs.push({
						id: produit.producteurId,
						libelle: produit.producteurLibelle,
						produits: []
					});
				}

				const index = this._.findIndex(this.sitesProducteurs, { id: produit.producteurId });
				const indexProduit = this._.findIndex(this.sitesProducteurs[index].produits, { id: produit.produitId });
				if (indexProduit == -1) {
					this.sitesProducteurs[index].produits.push({
						id: produit.produitId,
						libelle: produit.produitCode + ' - ' + produit.produitLibelle,
						idUnite: produit.uniteFacturationId ? produit.uniteFacturationId : produit.unitePeseeId,
						unite: produit.uniteFacturationLibelle ? produit.uniteFacturationLibelle : produit.unitePeseeLibelle
					});
				}
			},
			this
		);

		if (this.producteur) {
			angular.forEach(
				this.producteur.produits,
				function (produit) {
					if (produit.typeArticle == 0 && produit.idProducteur) {
						const index = this._.findIndex(this.sitesProducteurs, { id: produit.idProducteur });

						if (index != -1) {
							produit.produitsDisponibles = this.sitesProducteurs[index].produits;
							const indexProduitLigne = this._.findIndex(produit.produitsDisponibles, { id: produit.idProduit });
							if (indexProduitLigne == -1) {
								produit.produitsDisponibles.push({
									id: produit.idProduit,
									libelle: produit.libelle,
									idUnite: produit.idUnite,
									unite: produit.unite
								});
							}
						} else {
							this.sitesProducteurs.push({
								id: produit.idProducteur,
								libelle: produit.producteur,
								produits: []
							});
							produit.produitsDisponibles = [];
							produit.produitsDisponibles.push({
								id: produit.idProduit,
								libelle: produit.libelle,
								idUnite: produit.idUnite,
								unite: produit.unite
							});
						}
					}
				},
				this
			);
		}
	}

	async changeProduitformule(produit) {
		if (this.dateEcheanceFormule == null) {
			this.dateEcheanceFormule = this.offre.demande.demandeDateFin;
			this.messageFormule = 'Afficher produits avec formules expirées';
		} else {
			this.dateEcheanceFormule = null;
			this.messageFormule = 'Masquer produits avec formules expirées';
		}

		this.initProduit();
		this.changeProducteur(produit);
	}

	changeProducteur(produit) {
		if (this.sitesProducteurs.length > 0) {
			var index = null;
			if (produit.idProducteur == null) {
				index = this._.findIndex(this.sitesProducteurs, { id: this.producteur.idSiteCommercial });
				if (index == -1) {
					index = 0;
				}

				produit.idProducteur = this.sitesProducteurs[index].id;
			} else {
				index = this._.findIndex(this.sitesProducteurs, { id: produit.idProducteur });
			}

			produit.produitsDisponibles = this.sitesProducteurs[index].produits;
		}
	}

	getProduitsAjoutPossible() {
		return this.produitList;
	}

	addLigne() {
		switch (this.selectedLigneType) {
			case '0':
				this.addProduit();
				break;
			case '1':
				this.addTransport();
				break;
			case '2':
				this.addPrestation();
				break;
		}
	}

	$onDestroy() {}

	removeLigne(ligne, index) {
		index >= 0 && this.producteur.produits.splice(index, 1);
		if (ligne.idProduit) {
			const prestaLies = this.producteur.produits.filter((x) => x.idProduitLie == ligne.idProduit);
			for (let i = 0; i < prestaLies.length; i++) {
				const prestaLie = prestaLies[i];
				const indexPresta = this.producteur.produits.indexOf(prestaLie);
				this.producteur.produits.splice(indexPresta, 1);
			}
		}

		if (ligne.idPrestation) {
			const prestaLies = this.producteur.produits.filter((x) => x.idTransportLie == ligne.idPrestation);
			for (let i = 0; i < prestaLies.length; i++) {
				const prestaLie = prestaLies[i];
				const indexPresta = this.producteur.produits.indexOf(prestaLie);
				this.producteur.produits.splice(indexPresta, 1);
			}
		}

		// Si il n y a plus de ligne dans la condition de vente on rajoute une ligne produit vide comme à l initialisation
		if (this.producteur.produits.length == 0) {
			this.addNewProduit();
		}

		this.recalculeLignes();
	}

	addNewProduit() {
		const newProduit = new OffreProducteurLigneProduit();
		this.changeProducteur(newProduit);
		this.producteur.produits.push(newProduit);
		this.recalculeLignes();
	}

	addNewPrestation() {
		const newPrestation = new OffreProducteurLignePrestation();
		this.producteur.produits.push(newPrestation);
		this.recalculeLignes();
	}

	addNewTransport() {
		const newTransport = new OffreProducteurLigneTransport();
		this.producteur.produits.push(newTransport);
		this.recalculeLignes();
	}

	recalculeLignes() {
		this.checkIfIsTransported();
		let totalHT = 0;
		let totalTVA = 0;

		this.producteur.produits.forEach(function (ligne) {
			if (ligne.uniteQuantiteLieeAuProduit && ligne.typeArticle != 0) {
				ligne.quantiteDisabled = true;
			} else {
				ligne.quantiteDisabled = false;
			}

			// si c'est un produit
			if (ligne.typeArticle == 0) {
				const prestaLies = this.producteur.produits.filter((x) => x.idProduitLie == ligne.idProduit);
				for (let i = 0; i < prestaLies.length; i++) {
					const prestaLie = prestaLies[i];
					if (prestaLie.idUnite === ligne.idUnite) {
						prestaLie.quantite = ligne.quantite;
					}
				}

				// init 1ere ligne
				if (ligne.idProducteur == null) {
					this.changeProducteur(ligne);
				}

				if (ligne.prixFranco) {
					ligne.prixFranco = ligne.prixFranco * 1;
				} else {
					ligne.prixFranco = ligne.prixVente * 1;
				}

				if (this.proposition.isFranco) {
					this.producteur.produits.forEach(function (ligne2) {
						// si c'est un transport qui a la meme unit�
						if (ligne2.typeArticle == 1 && ligne.idUnite == ligne2.idUnite) {
							ligne.prixFranco += ligne2.prixVente * 1;
						}
					}, this);
				}
			}

			// si c'est un transport ou une prestation
			if (ligne.quantiteDisabled) {
				ligne.quantite = 0;
				this.producteur.produits.forEach(function (ligne2) {
					// si c'est un produit qui a la meme unité
					if (ligne2.typeArticle == 0 && ligne.typeArticle != 0 && ligne.idUnite == ligne2.idUnite) {
						if (ligne.idProduitLie) {
							//lié à UN produit, on prend la quantité du produit
							if (ligne2.idProduit == ligne.idProduitLie) ligne.quantite = ligne2.quantite * 1;
						} else {
							//non lié, on additionne tous les produits
							ligne.quantite += parseInt(ligne2.quantite);
						}
					}
				}, this);
			}

			if (ligne.quantite == 0) ligne.quantite = 1;

			// calcul des HT TTC
			// if (this.isTransported == true && ligne.prixProduitTransporte != null && ligne.quantite != null) {
			//     totalHT += ligne.prixProduitTransporte * ligne.quantite;
			//     totalTVA += ligne.prixProduitTransporte * ligne.quantite * ligne.tauxTVA / 100;
			// } else
			if (ligne.prixVente != null && ligne.quantite != null) {
				let totalHTLigne = ligne.prixVente * ligne.quantite;
				let totalTVALigne = (totalHTLigne * ligne.tauxTVA) / 100;
				if (ligne.isRemiseRechargement) {
					const produitLie = this.producteur.produits.find((x) => x.idProduit == ligne.idProduitLie);
					if (produitLie) {
						ligne.prixStandard = -((ligne.prixVente * produitLie.prixVente) / 100);
						totalHTLigne = ligne.prixStandard * ligne.quantite;
						totalTVALigne = (totalHTLigne * ligne.tauxTVA) / 100;
						ligne.remiseRechargementHT = totalHTLigne;
					}
				} else {
					if (ligne.isTgap) {
						const produitLie = this.producteur.produits.find((x) => x.idProduit == ligne.idProduitLie);
						if (produitLie.isTgapExclure) {
							totalHTLigne = 0;
							totalTVALigne = 0;
						}
					}
				}

				totalHT += totalHTLigne;
				totalTVA += totalTVALigne;
			}

			this.totaux.ttc[this.indexproducteur] = totalTVA + totalHT;
			this.totaux.tva[this.indexproducteur] = totalTVA;
			this.totaux.ht[this.indexproducteur] = totalHT;
		}, this);
	}

	checkIfIsTransported() {
		this.isTransported = false;

		this.producteur.produits.forEach(function (ligne) {
			if (ligne.typeArticle == 1) {
				this.isTransported = true;
			}
		}, this);
	}

	async changeProduit(produit) {
		const tarifProduit = await this.OffresService.getTarifProduit(
			this.clientid,
			this.producteur.idSiteCommercial,
			produit.idProduit,
			produit.idProducteur
		);
		produit.prixVente = tarifProduit.prix;
		produit.tauxTVA = tarifProduit.tauxTVA;
		produit.tvaId = tarifProduit.tvaId;
		produit.prixProduitTransporte = tarifProduit.transporte;
		if (tarifProduit.prixStandard) {
			produit.prixStandard = tarifProduit.prixStandard.prix;
		}

		const index = this._.findIndex(produit.produitsDisponibles, { id: produit.idProduit });
		produit.idUnite = produit.produitsDisponibles[index].idUnite;
		produit.unite = produit.produitsDisponibles[index].unite;
		produit.uniteQuantiteLieeAuProduit = produit.produitsDisponibles[index].uniteQuantiteLieeAuProduit;

		this.recalculeLignes();
	}

	async changePrestation(prestation) {
		let prixProduitLie = null;
		const presta = this.prestations.filter((p) => p.id == prestation.idPrestation)[0];
		if (presta.modeDeclenchement == 6) {
			// J0 : Je retirer la récupération du prix, car il doit en fait être calculé depuis un produit issu d'un autre bloc producteur
			// --> La remise rechargement est positionné sur le produit de sortie, mais le prix est calculé par rapport au produit d'entrée.
			// En attente de trouver une solution (un mantis sera créé), je préfère retirer le prix
			//let produitLies = this.producteur.produits.filter(p => p.typeArticle == 0);
			//prixProduitLie = produitLies[produitLies.length - 1].prixVente;
			prixProduitLie = 0.01;
			prestation.isRemiseRechargement = true;
		}
		const tarifPrestation = await this.OffresService.getTarifPrestation(
			this.clientid,
			this.producteur.idSiteCommercial,
			prestation.idPrestation,
			prixProduitLie
		);
		prestation.prixVente = tarifPrestation.prix;
		prestation.tauxTVA = tarifPrestation.tauxTVA;
		prestation.tvaId = tarifPrestation.tvaId;
		if (tarifPrestation.prixStandard) {
			prestation.prixStandard = tarifPrestation.prixStandard.prix;
		}

		const index = this._.findIndex(this.prestations, { id: prestation.idPrestation });
		prestation.idUnite = this.prestations[index].uniteId;
		prestation.unite = this.prestations[index].unite;
		if (presta.modeDeclenchement == 6) {
			prestation.uniteQuantiteLieeAuProduit = false;
		} else {
			prestation.uniteQuantiteLieeAuProduit = this.prestations[index].uniteQuantiteLieeAuProduit;
		}
		this.recalculeLignes();
	}

	async changeTransport(transport) {
		const tarifTransport = await this.OffresService.getTarifTransport(
			this.clientid,
			this.producteur.idSiteCommercial,
			transport.idPrestation,
			transport.idBenne,
			null,
			this.proposition.idLieu
		);
		if (tarifTransport.id != 0) {
			transport.prixVente = tarifTransport.prix;
			transport.tauxTVA = tarifTransport.tauxTVA;
			transport.tvaId = tarifTransport.tvaId;
			transport.prixAchatTransport = tarifTransport.prixAchat;
			if (tarifTransport.prixStandard) {
				transport.prixStandard = tarifTransport.prixStandard.prix;
			}
		} else {
			const tarifTransportBySociete = await this.OffresService.getTarifTransportBySociete(
				this.clientid,
				this.offre.demande.idSociete,
				transport.idPrestation,
				transport.idBenne,
				null,
				this.proposition.idLieu
			);
			transport.prixVente = tarifTransportBySociete.prix;
			transport.tauxTVA = tarifTransportBySociete.tauxTVA;
			transport.tvaId = tarifTransportBySociete.tvaId;
			transport.prixAchatTransport = tarifTransportBySociete.prixAchat;
			if (tarifTransportBySociete.prixStandard) {
				transport.prixStandard = tarifTransportBySociete.prixStandard.prix;
			}
		}

		const index = this._.findIndex(this.transports, { id: transport.idPrestation });
		transport.idUnite = this.transports[index].uniteId;
		transport.unite = this.transports[index].unite;
		transport.uniteQuantiteLieeAuProduit = this.transports[index].uniteQuantiteLieeAuProduit;

		this.recalculeLignes();
	}

	removeGroupe() {
		this.proposition.producteurs.splice(this.indexproducteur, 1);
	}

	getTotalTransportPrice() {
		let price = 0;

		this.producteur.produits.forEach(function (element) {
			if (element.typeArticle == 1) {
				price += element.prixVente;
			}
		}, this);

		return price;
	}

	async changeBenne(transport) {
		const tarifTransport = await this.OffresService.getTarifTransport(
			this.clientid,
			this.producteur.idSiteCommercial,
			transport.idPrestation,
			transport.idBenne,
			null,
			this.proposition.idLieu
		);
		if (tarifTransport.id != 0) {
			if (tarifTransport.prixStandard) {
				transport.prixStandard = tarifTransport.prixStandard.prix;
			} else {
				transport.prixStandard = null;
			}

			transport.prixVente = tarifTransport.prix;
			transport.prixAchatTransport = tarifTransport.prixAchat;
		} else {
			const tarifTransportBySociete = await this.OffresService.getTarifTransportBySociete(
				this.clientid,
				this.offre.demande.idSociete,
				transport.idPrestation,
				transport.idBenne,
				null,
				this.proposition.idLieu
			);
			if (tarifTransportBySociete.prixStandard) {
				transport.prixStandard = tarifTransportBySociete.prixStandard.prix;
			} else {
				transport.prixStandard = null;
			}

			transport.prixVente = tarifTransportBySociete.prix;
			transport.prixAchatTransport = tarifTransportBySociete.prixAchat;
		}
	}

	changeTva(produit) {
		const index = this._.findIndex(this.tvaList, { id: produit.tvaId });
		produit.tauxTVA = this.tvaList[index].taux;
		this.recalculeLignes();
	}

	async updateTgapExclure(id) {
		await this.OffresService.updateTgapExclure(id, 0);
	}
}
